<div class="navigation">
  <div class="navigation-logo-block"></div>
  <hr>
  <mat-nav-list class="navigation-content">
    <div class="navigation-item" mat-list-item [routerLink]="['/dashboard']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="dashboard-icon"></mat-icon>
      <span mat-line>{{'dashboard' | translate}}</span>
    </div>
    <div class="navigation-item" mat-list-item [routerLink]="['/templates']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="templates-icon"></mat-icon>
      <span mat-line>{{'templates' | translate}}</span>
    </div>
    <div class="navigation-item" mat-list-item [routerLink]="['/history']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="history-icon"></mat-icon>
      <span mat-line>{{'history' | translate}}</span>
    </div>
    <div class="navigation-item" mat-list-item [routerLink]="['/users']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="users-icon"></mat-icon>
      <span mat-line>{{'users' | translate}}</span>
    </div>
    <div class="navigation-item" mat-list-item [routerLink]="['/projects']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="projects-icon"></mat-icon>
      <span mat-line>{{'projects' | translate}}</span>
    </div>
  </mat-nav-list>
</div>
