<ul class="dashboard-room-filter">
  <!-- All buttons -->
  <li *ngIf="isAllVisible" [ngClass]="isAllSelected ? 'active' : ''" class="room-item text-capitalize dashboard-room-filter-item">
    <label [for]="selectAll" #roomfilter class="align-block-center checkbox-container">
      <input type="checkbox" (change)="selectAll()" [id]="selectAll" name="roomfilter"
             [checked]="isAllSelected">
      <span> {{'all' | translate}} </span>
    </label>
    <span *ngIf="isAllCircleVisible" class="rounded-circle history-chart-color" [ngStyle]="{'background': 'black'}"></span>
  </li>

  <!-- Item list -->
  <li *ngFor="let room of roomDashboardFilterList; let i = index" [ngClass]="room.selected ? 'active' : ''"
      class="room-item text-capitalize dashboard-room-filter-item">
    <label [for]="'room' + '-' + i" #roomfilter class="align-block-center checkbox-container">
      <input type="checkbox" (change)="setActive(room)" [id]="'room' + '-' + i" name="roomfilter"
             [checked]="room.selected">
      <mat-icon *ngIf="room.icon" [svgIcon]="room.icon" class="room-icon"></mat-icon>
      <span>{{room.name}}</span>
    </label>
    <span class="rounded-circle history-chart-color" *ngIf="room.chartColor" [ngStyle]="{'background': room.chartColor}"></span>
  </li>
</ul>
