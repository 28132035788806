<div class="share-project-modal">
  <div class="share-project-modal-header">
    {{'share_project' | translate}}
  </div>
    <!-- Is Admin -->
    <ul class="project-settings  mr-15">
        <li *ngFor="let setting of projectSettings.slice(0, 1); let i = index" (click)="setActive(i, 'setting', $event)">
            <label [for]="'setting' + '-' + i" class="align-block-center checkbox-container">
                <span class="ml-35 fw-700">{{setting?.title}}</span>
                <input class="setting-check" [checked]="setting?.selected" type="checkbox"
                       [ngStyle]="{'opacity':isAdmin ? 1 : 0.5}" [attr.disabled]="!isAdmin ? '' : null" 
                       [id]="'setting' + '-' + i">
            </label>
        </li>
    </ul>

  <!-- Rooms -->
  <div class="divider"></div>
  <div class="share-project-modal-body">
    <mat-accordion class="ml-35 mr-15" multi>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title class="fw-700">
            {{headers[1]?.title}}
            <span class="selected-rooms" title=""></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="mt-15">
          <li class="editable-item" *ngFor="let room of editableRooms; let i = index"
              (click)="setActive(i, 'room', $event)">
            <label [for]="'room' + '-' + i" class="d-flex mb-0 pb-24 justify-content-between checkbox-container">
              <span class="">{{(room?.name).toLowerCase() | translate}}</span>
              <input class="setting-check" [checked]="room?.selected" type="checkbox"
                     [ngStyle]="{'opacity':isAdmin ? 1 : 0.5}" [attr.disabled]="!isAdmin ? '' : null" 
                     [id]="'room' + '-' + i">
            </label>
          </li>
        </ul>
      </mat-expansion-panel>

      <!-- Sensors -->
      <div class="divider"></div>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title class="fw-700">
            {{headers[2]?.title}}
            <span class="selected-rooms" title=""></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="mt-15">
          <li class="editable-item" *ngFor="let value of editableValues; let i = index"
              (click)="setActive(i, 'value', $event)">
            <label [for]="'value' + '-' + i" class="d-flex pb-24 mb-0 justify-content-between checkbox-container">
              <span class="">{{(value?.type).toLowerCase() | translate}}</span>
              <input class="setting-check" [checked]="value?.selected" type="checkbox" 
                      [ngStyle]="{'opacity':isAdmin ? 1 : 0.5}" [attr.disabled]="!isAdmin ? '' : null" 
                      [id]="'value' + '-' + i">
            </label>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Other settings -->
    <div class="divider"></div>
    <ul class="project-settings  mr-15">
      <li *ngFor="let setting of projectSettings.slice(1, 10); let i = index" (click)="setActive(i + 1, 'setting', $event)">
        <label [for]="'setting' + '-' + i + 1" class="align-block-center checkbox-container">
          <span class="ml-35 fw-700">{{setting?.title}}</span>
          <input class="setting-check" [checked]="setting?.selected" type="checkbox" 
                 [ngStyle]="{'opacity':isAdmin ? 1 : 0.5}" [attr.disabled]="!isAdmin ? '' : null" 
                 [id]="'setting' + '-' + i + 1">
        </label>
        <div class="divider"></div>
      </li>
    </ul>
  </div>

  <!-- Buttons -->
  <div class="action-btns">
    <button mat-stroked-button color="primary" class="action-btn" (click)="dialogRef.close()">
      <span>{{'cancel' | translate}}</span>
    </button>
    <button mat-flat-button color="primary" class="action-btn" (click)="createLink()">
      <span>Share</span>
    </button>
  </div>
</div>
