<section class="schedular-actions">
    <div class="schedular-actions-header">{{data.title}}</div>
    <div class="schedular-actions-body">
        <form>
            <label for="name">* {{'template_name' | translate}}</label>
            <input [(ngModel)]="data.name" [ngModelOptions]="{standalone: true}" class="w-100" id="name" type="text" placeholder="Name" #name [disabled]="data.action === 'apply'"  >
            <h5 class="set-for">* {{'set_for' | translate}}:</h5>
            <ul class="days-selection">
                <li (click)="selectDay(ind)" class="days-selection-item" [ngClass]="{'selected': day.selected}" *ngFor="let day of days; let ind = index">
                    {{('day_' + day.name.toLowerCase() + '_short') | translate}}
                </li>
            </ul>
            <div class="d-flex justify-content-end">
                <button class="cancel-button" [mat-dialog-close]="false">
                    {{'cancel' | translate}}
                </button>
                <button class="apply-button" [mat-dialog-close]="{days: days, name: name.value}">
                    {{'save' | translate}}
                </button>
            </div>

        </form>
    </div>
</section>