import {Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  activeProject = localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')) : '';

  private autoLogoutSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher
  ) {

    this.mobileQuery = media.matchMedia('(max-width: 1000px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngAfterViewInit(): void {
    const getActiveProjectByInterval = setInterval(()=> {
      this.activeProject = localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')) : '';
      if (this.activeProject) {
        clearInterval(getActiveProjectByInterval);
      }
    }, 500);

    setTimeout( ()=> {
      clearInterval(getActiveProjectByInterval);
    }, 120000);

    this.changeDetectorRef.detectChanges();
  }
}
