import {Sensor} from "./sensor";

export enum dayEnum {
  'monday' = 0,
  'tuesday' = 1,
  'wednesday' = 2,
  'thursday' = 3,
  'friday' = 4,
  'saturday' = 5,
  'sunday' = 6
}

export interface Template {
  id: number;
  name: string;
  uuid: string;
  default: boolean;
  isActive: boolean;
  icon?: string;
  values: string[][];
  sensorType: Sensor;
}

// Day template
export interface SchedulerTemplate {
  day: string,
  templateId: number,
  template?: Template,
  syncDirtyAt: Date | null,
  createdAt: Date,
  updatedAt: Date
}

export interface SensorScheduler {
  type: string;
  templates: SchedulerTemplate[];
}

export interface RoomScheduler {
  roomUuid: string,
  name: string,
  roomId: number,
  schedulers: SensorScheduler[]
}

export interface ProjectScheduler {
  rooms: RoomScheduler[]
}
