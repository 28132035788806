export type Sensor = 'heating' | 'humidity' | 'airflow' | 'co2' | 'voc' | 'light' | 'alarm' | 'valve' | 'heatingTemp' | 'heatingFlow';
export const RANGE_SENSORS: Sensor[] = [ 'humidity', 'airflow', 'co2', 'voc'];

export function getSensorRange(type: Sensor): { yMin: number, yMax: number } {
  switch (type) {
    case 'heating':
      return {yMin: 12, yMax: 28};
    case 'heatingTemp':
      return {yMin: 0, yMax: 100};
    case 'heatingFlow':
      return {yMin: 0, yMax: 200};
    case 'humidity':
      return {yMin: 0, yMax: 100};
    case 'light':
      return {yMin: 0, yMax: 100};
    case 'alarm':
      return {yMin: 0, yMax: 100};
    case 'valve':
      return {yMin: 0, yMax: 100};
    case 'co2':
      return {yMin: 400, yMax: 1000};
    case 'voc':
      return {yMin: 0, yMax: 100};
    case 'airflow':
      return {yMin: 0, yMax: 100};
  }
}

/// Convert value from scheduler space to sensor unit space 
export function schedulerValueToUnit(type: Sensor, sensorsCount: number) {
  return function (value: number): number {
    switch (type) {
      case 'airflow':
        return airFlowSchedulerValueToUnit(value, sensorsCount);
      default:
        return value;
    }
  }
}

/// Convert airflow % to m3/h
export function airFlowSchedulerValueToUnit(value: number, airGateCount: number): number {
  return value * 60.0 * airGateCount / 100.0;
}