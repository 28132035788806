<div class="system-status-section">
  <div class="system-status-section-title">
    {{'system_status' | translate}}:
    <div class="online-status" *ngIf="activeProjectStatus && activeProjectStatus.online">{{'online' | translate}}</div>
    <div class="online-status" *ngIf="activeProjectStatus && !activeProjectStatus.online">{{'offline' | translate}}</div>
  </div>
  <span class="right-divider"></span>
  <div class="system-status-notification"
       [matMenuTriggerFor]="menu"
       #trigger="matMenuTrigger"
       [ngClass]="{'active': trigger.menuOpen}"
       [routerLinkActive]="'active'"
  >
    <mat-icon svgIcon="notification-icon"></mat-icon>
  </div>
  <mat-menu #menu="matMenu" yPosition="above" class="notification">
    <ul class="notification">
      <li *ngFor="let notification of notifications$ | async" class="notification-item hide">
        <span class="notification-item-badge"
              [class.active]="notification.status === 'active'"
              [class.inactive]="notification.status === 'inactive'"
              [class.warn]="notification.status === 'warn'"
        ></span>
        <div>
          <div class="notification-item-title">{{notification.title}}</div>
          <div class="notification-item-desc">{{notification.description}}</div>
        </div>
      </li>
      <li class="notification-item">
        <div>
          <div class="notification-item-title">{{'no_notification' | translate}}</div>
        </div>
      </li>
      <mat-button [routerLink]="['/notifications']" class="show-all">{{'show_all' | translate}}</mat-button>
    </ul>
  </mat-menu>
  <span class="right-divider"></span>
  <div class="system-status-profile">
    <span class="user-name hidden-sm-down" *ngIf="currentUser && currentUser.email">{{currentUser.email}}</span>
    <div class="user-profile-menu-block"
         [matMenuTriggerFor]="profileMenu"
         #profileTrigger="matMenuTrigger"
         [ngClass]="{'active': profileTrigger.menuOpen}"
         [routerLinkActive]="'active'">
      <img src="/assets/images/profile-img.png" alt="" class="user-profile-menu-block-img"/>
      <mat-menu #profileMenu="matMenu" yPosition="above" class="user-profile-menu-element">
        <ul class="user-profile-menu-element">
          <li class="user-profile-menu-element-item"
              mat-menu-item [matMenuTriggerFor]="languages"
          >
            <div>
              <div class="user-profile-menu-element-item-title">Language</div>
            </div>
          </li>
          <li class="user-profile-menu-element-item" (click)="navigateToSupport()" mat-menu-item [disabled]="!currentUser?.id">
            <mat-icon svgIcon="support" class="user-profile-menu-element-item-badge"></mat-icon>
            <div class="user-profile-menu-element-item-title">{{'support' | translate}}</div>
          </li>
          <li class="user-profile-menu-element-item" (click)="onLogOut()"  mat-menu-item>
            <mat-icon svgIcon="log-out" class="user-profile-menu-element-item-badge"></mat-icon>
            <div>
              <div class="user-profile-menu-element-item-title">{{'log_out' | translate}}</div>
            </div>
          </li>

          <mat-menu #languages="matMenu" class="mat-menu-language">
            <button (click)="changeLang('en')" mat-menu-item>
              <mat-icon [style.visibility]="getCurrentlang() === 'en' ? 'visible' : 'hidden'">check</mat-icon>
              EN
            </button>
            <button (click)="changeLang('de')" mat-menu-item>
              <mat-icon  [style.visibility]="getCurrentlang() === 'de' ? 'visible' : 'hidden'">check</mat-icon>
              DE
            </button>
          </mat-menu>
        </ul>
      </mat-menu>
    </div>
  </div>
</div>
