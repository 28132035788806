import {Component, Input, OnInit, Output, QueryList, ViewChildren, EventEmitter, OnChanges} from '@angular/core';
import {ResizeService} from '../../../../core/services/resize/resize.service';

@Component({
  selector: 'app-top-filter',
  templateUrl: './top-filter.component.html',
  styleUrls: ['./top-filter.component.scss']
})
export class TopFilterComponent implements OnInit, OnChanges {

  @Input() topFilterList: any;
  @ViewChildren('filters') filters: QueryList<any>;
  @Output() setFilterSensorTypeValue = new EventEmitter();
  @Output() setFilterSensorType = new EventEmitter();
  onlyIconsFilter = false;
  activeLink;
  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    this.setActiveSensor(changes.topFilterList.currentValue[0]);
    this.activeLink = changes.topFilterList.currentValue[0];
  }

  setActiveSensor(sensor) {
    if (!sensor) return;
    this.topFilterList.map((f) => {
      return f.selected = false;
    });
    sensor.selected = !sensor.selected;

    this.setFilterSensorType.emit(sensor);
    this.setFilterSensorTypeValue.emit(sensor.type);
  }

}