<div class="data-range-calendar">
    <!--  <mat-icon (click)="picker.open()" svgIcon="calendar" class="calendar-icon"></mat-icon>-->

    <!--  <mat-form-field appearance="fill" class="hide-calendar-range-input">-->
    <!--    <mat-label>Enter a date range</mat-label>-->
    <!--    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">-->
    <!--      <input matStartDate formControlName="start" placeholder="Start date">-->
    <!--      <input matEndDate formControlName="end" placeholder="End date"-->
    <!--             (dateChange)="dateRangeChange(dateRange.controls.start, dateRange.controls.end)">-->
    <!--    </mat-date-range-input>-->
    <!--    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>-->
    <!--    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>-->
    <!--    <mat-date-range-picker #picker color="primary" panelClass="datepicker"></mat-date-range-picker>-->
    <div [formGroup]="dateRange" class="d-flex">
        <mat-form-field appearance="fill">
            <mat-label>{{'start_date' | translate}}</mat-label>
            <input readonly
                   matInput
                   [matDatepicker]="startDate"
                   formControlName="start"
                   [max]="maxDate"
                   (focus)="startDate.open()"
            >
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate startView="year" [startAt]="dateRange.controls.start"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'end_date' | translate}}</mat-label>
            <input
                    matInput [matDatepicker]="endDate"
                    formControlName="end"
                    [max]="maxDate"
                    (focus)="endDate.open()"
                    readonly
            >
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate startView="year" [startAt]="dateRange.controls.end"></mat-datepicker>
        </mat-form-field>

        <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <!--  </mat-form-field>-->
    </div>

</div>