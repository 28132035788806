import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api';
import {Project} from '../../../models/project.model';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {AuthService} from '..';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  projects$ = new BehaviorSubject<any>(null);
  activeProject$ = new BehaviorSubject<any>(null);

  constructor(private apiService: ApiService, private authService: AuthService) {
    const token = this.authService.getToken();
    if (token.length > 0) {
      this.getProjectsList();
    }
  }

  public getProjectsList() {
    this.getProjects().pipe(
        map(res => {
          this.getProjectsAlias({aliasType: 'project'}).subscribe(resp => {
            const projects = res.data.map(project => {
              const alias = resp.data.find(p => p.modelUuid === project.uuid);
              if (alias) {
                return {
                  ...project,
                  name: alias.alias + "*"
                }
              } else {
                return project;
              }
            }).sort((p1, p2) => ((p1.name || "").toLowerCase() < (p2.name || "").toLowerCase() ? -1 : 1));
            
            this.projects$.next(projects);
            const activeProject = localStorage.getItem('activeProject') ?
                JSON.parse(localStorage.getItem('activeProject')) : this.projects$.getValue()[0];
            return (activeProject && activeProject.id) ? this.setActiveProject(activeProject.id).subscribe() : false;
          })
        }),
       ).subscribe();
  }

  sendUpdateProjectList(body: any) {
    return this.projects$.next(body);
  }

  getUpdateProjectList(): Observable<any> {
    return this.projects$.asObservable();
  }

  getProjects() {
    return this.apiService.get(`/publish_project/shortInfo`);
  }

  getProjectStatus(deviceId) {
    return this.apiService.get(`/mdash/device/${deviceId}`);
  }

  setActiveProject(id) {
    return this.apiService.get(`/publish_project/${id}`)
        .pipe(map(res => {
          this.getProjectsAlias({modelUuid: res.project.uuid, aliasType: 'project'}).subscribe(resp => {
            let projectName = res.project.name;
            if (resp.data[0] && resp.data[0].alias) {
              projectName = resp.data[0].alias + "*";
              res.project.alias = resp.data[0].alias + "*";
            }
            res.project.alias = projectName;
            localStorage.setItem('activeProject', JSON.stringify({...res.project, name: projectName, devices: res.devices}));
            this.activeProject$.next(res)
          })
        }));
  }

  getProjectById(id) {
    return this.apiService.get(`/publish_project/${id}`);
  }

  getProjectsAlias(data: {modelUuid?: string, aliasType: string}) {
    const modelUuid = data.modelUuid ? `&modelUuid=${data.modelUuid}`: '';
    return this.apiService.get(`/alias?aliasType=${data.aliasType}${modelUuid}`);
  }

  projectChangeName(data: any) {
    return this.apiService.put(`/publish_project/changeName/`, data);
  }

  projectChangeAlias(data: any) {
    return this.apiService.post(`/alias/bulk`, {data: data});
  }

  projectWifiChangeAPSettings(data: any) {
    return this.apiService.put(`/wifi/changeAPSettings`, data);
  }

  projectWifiChangeSTSettings(data: any) {
    return this.apiService.put(`/wifi/changeSTSettings`, data);
  }

  removeProjectById(id) {
    return this.apiService.delete(`/project?ids=${id}`);
  }

  getTemplateForDemoProject() {
    return this.apiService.get(`/project/demo/public`);
  }

  postCreateDemoProject(id) {
    return this.apiService.post(`/project/demo/${id}`, {});
  }

  getActiveProjectSensors(id) {
    return this.apiService.get(`/publish_project/${id}/sensors`);
  }

  getActiveProjectSensorValues(id) {
    return this.apiService.get(`/sensor/publishProject/${id}/values`).pipe(map(res =>{
      res.data.map((item)=>{
        item.sensors.map((sensor)=>{
          sensor.value = parseFloat(sensor.value).toFixed(1);
        })
      });
      return res;
    }));
  }

  postNewRoomProperty(body) {
    return this.apiService.post(`/template/active`, body);
  }


  getProjectUsers(projectId) {
    return this.apiService.get(`/project/${projectId}/user`).pipe(map(res => res.data))
  }

  public deleteUser(projectId, userId) {
    return this.apiService.delete(`/project/${projectId}/user/${userId}`)
  }
}
