<div class="project-adjustments-modal">
    <div class="project-adjustments-modal-header">
        {{'edit_project_adjustments' | translate}}
    </div>

    <div class="project-adjustments-modal-body">
        <mat-accordion class="ml-35 mr-15" multi>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let room of projectsRooms; let i = index">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{room.name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ul class="mt-15">
                    <li class="editable-item"
                        *ngFor="let measurement of room.measurements | keyvalue; trackBy: trackByFn">
                        <span>{{MeasurementEnum[measurement.key]}}</span>
                        <input 
                            type="number"
                            [ngModel]="projectsRooms[i].measurements[measurement.key]"
                            (ngModelChange)="checkNumber($event, projectsRooms[i].measurements, measurement.key)"
                        >
                    </li>
                </ul>

            </mat-expansion-panel>
        </mat-accordion>
    </div>


    <div class="action-btns">
        <button mat-stroked-button color="primary" class="action-btn" (click)="dialogRef.close()">
            <span>{{'cancel' | translate}}</span>
        </button>
        <button mat-flat-button color="primary" class="action-btn" (click)="saveMeasurements()">
            <span>{{'save' | translate}}</span>
        </button>
    </div>
</div>
