import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../../core/services/notification/notification.service';
import {AuthService} from '../../../../../core/services';
import {ProjectsService} from '../../../../../core/services/projects/projects.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/index';
import {UsersService} from '../../../../../core/services/users/users.service';
import {TranslateService} from '@ngx-translate/core';
import { AppService } from 'src/app/core/services/app/app.service';

@Component({
  selector: 'app-system-status-section',
  templateUrl: './system-status-section.component.html',
  styleUrls: ['./system-status-section.component.scss'],
})
export class SystemStatusSectionComponent implements OnInit, OnDestroy {

  notifications$;
  activeProject;
  activeProjectStatus;
  currentUser;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private usersService: UsersService,
    private cdRef: ChangeDetectorRef,
    private projectsService: ProjectsService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private appService: AppService
  ) {
  }

  ngOnInit() {
    this.notifications$ = this.notificationService.getNotifications();
    this.getStatusByActiveProject();
  }

  getStatusByActiveProject() {
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.activeProjectStatus = '';
        this.activeProject =  res;
        if (this.activeProject.project.mDash && this.activeProject.project.mDash.deviceId) {
          this.getProjectStatus(this.activeProject.project.mDash.deviceId);
        } else {
          this.activeProjectStatus = {online: false};
        }
      }

      this.getProjectUser();
    });
    
    this.appService.validateVersion();
  }

  getProjectStatus(deviceId){
    if (!deviceId) return;
    this.projectsService.getProjectStatus(deviceId).subscribe(res => {
      this.activeProjectStatus = res;
      this.cdRef.detectChanges();
    })
  }

  getProjectUser() {
    this.usersService.getCurrentUser().subscribe(res => {
      this.currentUser = res;
      this.cdRef.detectChanges();
    })
  }

  onLogOut() {
      this.authService.logOut();
  }

  changeLang(lang) {
    this.translate.use(lang);
  }

  getCurrentlang() {
    return this.translate.currentLang;
  }

  navigateToSupport() {
    let url = `https://climeo.com/en/send-request-page/?user=${this.currentUser.id}`;
    if (this.activeProject?.project?.uuid) url += `&project=${this.activeProject?.project?.uuid}`
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
