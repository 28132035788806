import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deprecated-app-warning',
  templateUrl: './deprecated-app-warning.component.html',
  styleUrls: ['./deprecated-app-warning.component.scss']
})
export class DeprecatedAppWarningComponent implements OnInit {

  url: string = environment.WEB_V2_URL;
  
  constructor() { }

  ngOnInit(): void {
  }

  onOpenNewVersion() {
    window.location.replace(this.url);
  }
}
