import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private apiService: ApiService) {}

  getRoomHistoryData(roomUuid: any, projectUuid?: any, sensorTypes?: string[], endTime?: Date, startTime?: Date) {
    return this.apiService.get(`/history/${projectUuid}/room/${roomUuid}?type=${sensorTypes.join()}&endTime=${endTime.toISOString()}&startTime=${startTime.toISOString()}`);
  }

  getSensorHistoryData(sensorType: any, projectUuid?: any, roomsUuids?: string[], endTime?: Date, startTime?: Date) {
    return this.apiService.get(`/history/${projectUuid}/sensor/${sensorType}/?roomsUuid=${roomsUuids.join()}&endTime=${endTime.toISOString()}&startTime=${startTime.toISOString()}`);
  }

  getProjectAdjustment(projectUuid) {
    return this.apiService.get(`/projectAdjustment?projectUuid=${projectUuid}`).pipe(map(res => res.data));
  }

  getSensorRules(projectUuid) {
    return this.apiService.get(`/projectSensor/rules?projectUuid=${projectUuid}`).pipe(map(res => res.data));
  }

  postProjectAdjustments(data) {
    return this.apiService.post('/projectAdjustment/bulk', {data})
  }

  postRoomHistoryData(data) {
    return this.apiService.post('/history/export', data)
  }
}
