<div class="link-created-dialog">
    <div class="link-created-dialog-header">
       {{'ready_link' | translate}}
    </div>
    <div class="link-created-dialog-body">
        <h5 class="link-created-dialog-body-title">
            {{'ready_link_message' | translate}}
        </h5>
        <div class="created-link">
            <input [style.pointer-events]="'none'" readonly class="link" type="text" [value]="data">
            <span (click)="copyLink()" class="copy-button">{{'copy_link' | translate}}.</span>
        </div>
        <div class="action-btns">
            <button mat-stroked-button color="primary" class="action-btn" (click)="dialogRef.close()">
                <span>{{'cancel' | translate}}</span>
            </button>
        </div>

    </div>

</div>
