import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AlertService} from "../../../core/services";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-link-created-dialog',
  templateUrl: './link-created-dialog.component.html',
  styleUrls: ['./link-created-dialog.component.scss']
})
export class LinkCreatedDialogComponent implements OnInit {

  redirectUrl:string;
  urlToken: string;

  constructor(public dialogRef: MatDialogRef<LinkCreatedDialogComponent>,
              private alertService: AlertService,
              @Inject(MAT_DIALOG_DATA)
              public data: any,
              private router: Router
              ) { }

  ngOnInit(): void {}

  copyLink() {
    let copyText = document.querySelector(".link") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
    this.alertService.openInfoModal('', 'Link has succesfully copied')
  }

}
