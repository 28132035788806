import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
  name: 'FilterBySensor',
  pure: false
})
export class FilterBySensor implements PipeTransform {

  transform(roomList : any, filterBySensorValue: string): any[] {
    if (!filterBySensorValue) return roomList;
    if (roomList) {
      roomList.map((room) => {
        if (room.selected && room.sensors && room.sensors.length > 0) {
          if (filterBySensorValue !== 'all' && room.sensors) {
            return room.sensors = room.sensors.filter((listing: any) => listing.type === filterBySensorValue);
          }
          if (filterBySensorValue === 'all' || !filterBySensorValue ) {
            return roomList;
          }
        } else {
          return roomList;
        }
      });
      return roomList;
    }
  }
}
