import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment'
import {HttpClient} from '@angular/common/http';
import {Weather} from '../../../models';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ApiService} from '../../api/api';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  getWeather(longitude, latitude): Observable<any> {
    return this.apiService.get(`/weather/?longitude=${longitude}&latitude=${latitude}`);
  }
}
