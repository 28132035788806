import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {ProjectScheduler} from '../../../models/scheduler.model';
import {SchedulerUtils} from './schedulerUtils';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  constructor(private apiService: ApiService,
              private schedulerUtils: SchedulerUtils) {
  }

  get activeProject() {
    return localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')) : '';
  } 

  getScheduler(projectId, roomsIds?: string[]): Observable<ProjectScheduler> {
    return this.apiService.get(`/scheduler/publishProject/${projectId}/?rooms=${roomsIds.join()}`)
      .pipe(
        map(res => {
          // Sort template values 
          if (res['data'].template){
            this.schedulerUtils.sortTemplateValues(res['data'].template);
          }

          // Set template
          res['data'].rooms.forEach(room => {
            room.schedulers.forEach(scheduler => {
              scheduler.templates.forEach(template => {

                template.template = res['data'].template.find(obj => obj.id === template.templateId)
              });
              room.setValues = (this.activeProject) &&
                this.schedulerUtils.getActiveSchedulers(res.data, this.activeProject)[room.roomUuid]
            })
          });
          return res.data;
        })
      );
  }
}
