import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../../core/services/projects/projects.service';
import {AlertService} from '../../../core/services';
import {PermissionsService} from "../../../core/services/permissions/permissions.service";
import {switchMap} from "rxjs/operators";
import {PERMISSIONS} from "../../../models/permission.model";
import {of} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-dashboard-rooms',
  templateUrl: './dashboard-rooms.component.html',
  styleUrls: ['./dashboard-rooms.component.scss']
})
export class DashboardRoomsComponent implements OnInit {

  @Input() roomDashboardFilterList: any;
  @Input() filterBySensorType: string = 'all';
  @ViewChildren('roomfilter') roomfilter: QueryList<any>;
  private oldRoomValue;
  private valuesPermissions = [];
  time = {hour: 1 , minute: 0 };
  ctrlTime = new FormControl({hour: 1 , minute: 0 }, (control: FormControl) => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (value.hour === 0) {
      this.ctrlTime.reset(this.time);
      return  {isZero: true};
    }

    return null;
  });

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private activeRoute: ActivatedRoute,
              private alertService: AlertService,
              private projectsService: ProjectsService,
              private permissionsService: PermissionsService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.projectsService.activeProject$.pipe(switchMap(project => {
      if (project) {
        return this.permissionsService.getPermission(PERMISSIONS.SENSORS, project.project.id)
      } else {
        return of(null)
      }
    })).subscribe(res => {
      this.valuesPermissions = res;
    })
  }

  get noneEditableSensors() {
    return ['voc', 'co2'].includes(this.filterBySensorType);
  }

  setEditMode(room){
    room.isEditMode = true;
    this.oldRoomValue = _.cloneDeep(room);
  }

  onTimeChange(e){
    if (!this.ctrlTime.valid) {
      e.target.value = '01';
      this.time.hour = 1;
    }
  }

  updatePropertyValue(property, room , event) {
    property.set = event.value;
    this.setSelectedTemperatureSensors(property, room);
    property.changed = true;
  }

  cancelEditingMode(room){
    room.sensors = this.oldRoomValue.sensors;
    room.isEditMode = false;
  }

  setPropertyValue(property, set) {
    if (!property.set) {
      property.set = parseFloat(set).toFixed(1).toString();
    }
    return true;
  }

  setNewTime() {
    const hours = (this.ctrlTime.value.hour) ? this.ctrlTime.value.hour * 3600 : 0;
    const minutes = (this.ctrlTime.value.minute) ? this.ctrlTime.value.minute * 60 : 0;
    return hours + minutes;
  }

  saveEditingMode(room){
    const editedRoom = room.sensors.filter(rs=> rs.changed);
    this.setNewRoomProperty(editedRoom, room);
  }

  increasePropValue(property, room){
    property.set = (parseFloat(property.set) + 1).toFixed(2).toString();
    this.setSelectedTemperatureSensors(property, room);
    property.changed = true;
  }

  setSelectedTemperatureSensors(property, room) {
    let temperatureSensors = [];
    if (property.type === 'heating' || property.type === 'valve' || property.type ===  'heatingFlow') {
      temperatureSensors = room.sensors.filter(rs=> rs.type === 'heating'|| rs.type === 'valve'|| rs.type === 'heatingFlow');
      temperatureSensors.map( t=> {
        t.changed = false;
      });
    }
  }

  decreasePropValue(property, room){
    property.set = (parseFloat(property.set) - 1).toFixed(2).toString();
    this.setSelectedTemperatureSensors(property, room);
    property.changed = true;
  }

  goToScheduler(room, property) {
    if (property.isEditable) {
      this.router.navigate(['./scheduler', room.projectId, room.id, property.type],
        {state: {data: { breadcrumb: room.name}}, relativeTo: this.activeRoute.parent});
    }
  }

  setNewRoomProperty(editedRoom,room) {
    const newRoomVal = [];
    editedRoom.map(sensor => {
      return newRoomVal.push({type: sensor.type, value: sensor.set});
    });

    const newRoomData = {
      data: {
        projectId: room.projectId,
        projectUuid: room.projectUuid,
        roomId: room.id,
        time: this.setNewTime(),
        values: newRoomVal
      }
    };
    if (newRoomVal.length > 0) {
      this.projectsService.postNewRoomProperty(newRoomData).subscribe(resp => {
          this.alertService.openInfoModal(
              this.translate.instant('successfully_updated'),
              this.translate.instant('success')
          );
          room.isEditMode = false;
        },
        (err) => {
          this.alertService.openErrorModal('Error', err.error.error.message);
        })
    }
  }

  noPermission(type) {
    return this.valuesPermissions?.includes(type);
  }

}
