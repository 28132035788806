export const PERMISSIONS = {
  SHARE_PROJECT: 'shareProject',
  ROOMS: 'rooms',
  IS_ADMIN: 'admin',
  SENSORS: 'sensors',
  CHANGE_NAME: 'changeName',
  CHANGE_ROOM_NAME: 'changeRoomName'
};

export const PERMISSIONS_KEYS = {
  shareProject: '/project/share/enabled',
  sensors: '/sensors/disabled',
  changeName: '/project/name/change',
  changeRoomName: '/project/roomName/change',
  rooms: '/rooms/enabled',
  admin: '/isAdmin'
};

export const FULL_PERMISSIONS = [
  {key: '/isAdmin', type: 'bool', value:  true},
  {key: '/rooms/enabled', type: 'roomList', value:  []},
  {key: '/sensors/disabled', type: 'sensorList', value:  []},
  {key: '/project/share/enabled', type: 'bool', value: true},
  {key: '/project/name/change', type: 'bool', value: false},
  {key: '/project/roomName/change', type: 'bool', value: false},
];

export interface Permission {
  key: string;
  type: string;
  value: [string] | boolean;
}

export interface ProjectPermission {
  createdAt: string;
  permissions: Permission[];
  projectId: number;
  projectUuid: string;
  updatedAt: string
}