import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private apiService: ApiService) {}

  getTemplates(activeProjectId?: any) {
    const id = localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')).id : activeProjectId;
    return this.apiService.get(`/template/publishProject/${id}`);
  }
  deleteTemplate(templateId) {
    return this.apiService.delete(`/template/${templateId}`);
  }
  applyTemplate(body) {
    const activeProject = localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')) : '';
    return this.apiService.put(`/scheduler/publishProject/${activeProject.id}/applyTemplate`, body);
  }
  createTemplate(template) {
    return this.apiService.post(`/template/bulk`, template);
  }
  setTemplateForCustomization(template: object) {
    localStorage.setItem('templateSource', JSON.stringify(template));
  }
  getTemplateForCustomization() {
    return JSON.parse(localStorage.getItem('templateSource'))
  }
}
