import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ResizeService} from '../../../core/services/resize/resize.service';
import {EventEmitter} from  '@angular/core' ;
import 'chartjs-plugin-zoom';
import { forkJoin } from 'rxjs';
import Chart from 'chart.js'

@Component({
  selector: 'app-history-chart',
  templateUrl: './history-chart.component.html',
  styleUrls: ['./history-chart.component.scss']
})

export class HistoryChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('historyChart') canvas: ElementRef<HTMLCanvasElement>;
  public ctx: CanvasRenderingContext2D;
  @Input() dataSet;
  dataChart;

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    hover: {
      animationDuration: 0
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true
        },
        type: 'time',
        position: 'bottom',
        time: {
          displayFormats: {
            hour: 'DD, MMMM  h:mm'
          }
        },
        ticks: {
          maxRotation: 90,
          minRotation: 0
        },
        gridLines: {
          borderDash: [8, 4],
          color: '#979797'
        }
      }, {
        position: 'top',
        ticks: {
          display: false
        },
        gridLines: {
          display: false,
          drawTicks: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero:true
        },
        gridLines: {
          display:false,
          drawBorder: true
        }
      }, {
        position: 'right',
        ticks: {
          display: false
        },
        gridLines: {
          display: false,
          drawTicks: false
        }
      }]
    },
    plugins: {
      zoom: {
        // Container for pan options
        pan: {
          // Boolean to enable panning
          enabled: true,

          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          mode: 'xy'
        },

        // Container for zoom options
        zoom: {
          // Boolean to enable zooming
          enabled: true,

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: 'xy',
        }
      }
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    }
  };

  constructor() {
  }

  ngOnInit(){
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.dataChart = new Chart(this.ctx, {
      type: 'line',
      data: this.dataSet.data,
      options: this.chartOptions
    });
    this.dataChart.update();
  }

  ngAfterViewInit(){
  }

  ngOnChanges(changes: any) {
    if (this.dataChart) {
      this.dataChart.data = changes.dataSet.currentValue.data;
      this.dataChart.update();
    }
  }
  ngOnDestroy(){}
}
