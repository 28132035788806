import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {WeatherService} from '../../../../../core/services/weather/weather.service';
import {Weather} from '../../../../../models';
import {ProjectsService} from '../../../../../core/services/projects/projects.service';

@Component({
  selector: 'app-weather-section',
  templateUrl: './weather-section.component.html',
  styleUrls: ['./weather-section.component.scss'],
})
export class WeatherSectionComponent implements OnInit, OnDestroy {

  public weather: Weather;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  activeProject;
  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private projectsService: ProjectsService,
    private weatherService: WeatherService
  ) {
  }

  ngOnInit() {
    this.getWeatherByActiveProject();
  }

  getWeatherByActiveProject() {
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.activeProject =  res;
        this.getWeather(this.activeProject.project.location.longitude.toString(), this.activeProject.project.location.latitude.toString());
      }
    });
  }
  
  getWeather(longitude, latitude) {
    this.weatherService.getWeather(longitude, latitude).subscribe(res => {
      this.weather = res;
      this.cdRef.detectChanges();
    })
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
