import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ShareProjectService} from '../../../core/services/share-project/share-project.service';
import {LinkCreatedDialogComponent} from '../link-created-dialog/link-created-dialog.component';
import {ProjectsService} from '../../../core/services/projects/projects.service';
import {map, switchMap} from 'rxjs/operators';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { PERMISSIONS_KEYS, FULL_PERMISSIONS, PERMISSIONS } from 'src/app/models/permission.model';
import { combineLatest } from 'rxjs';
import {USER_ROLES} from '../../../models/userRoles';

@Component({
  selector: 'app-share-project',
  templateUrl: './share-project.component.html',
  styleUrls: ['./share-project.component.scss']
})
export class ShareProjectComponent implements OnInit {
  projectSettings = [];
  editableValues = [];
  editableRooms = [];
  headers = [];
  projectPermissions = [];
  isAdmin: boolean = true;

  constructor(
      public dialogRef: MatDialogRef<ShareProjectComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA)
      public data: any,
      private shareProjectService: ShareProjectService,
      private projectsService: ProjectsService,
      private permissionsService: PermissionsService
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.permissionsService.getAllPermission(this.data.projectId), 
      this.projectsService.getActiveProjectSensors(this.data.projectId)
    ]).pipe(switchMap(res => {
      this.getEditableValuesAndTemplates(res[0], res[1]);
      this.projectPermissions = res[0];
      return this.shareProjectService.getForm().pipe(map(el => [el, res[0]]));
    }))
    .subscribe(([form, permissions]) => {
      this.initUIPermissions(form, permissions)
    })
  }

  initUIPermissions(form, permissions) {
    const projectId = this.data.projectId;

    // Bool setting
    this.projectSettings = form.filter(el => el.type == 'bool').map(permission => { 
      let isSelected = FULL_PERMISSIONS.find(el => el.key === permission.key).value;
      return { 
        ...permission,
        selected: isSelected == undefined ? true : isSelected
      } 
    });

    // Headers
    this.headers = form.filter(el => el.type === 'header');

    // Is Admin 
    this.isAdmin = this.permissionsService.getPermissionSync(PERMISSIONS.IS_ADMIN, projectId, true);
    this.projectSettings.find(el => el.key == PERMISSIONS_KEYS.admin).selected = this.isAdmin;
      
    
    if (!this.isAdmin){
      // Bool settings 
      this.projectSettings.forEach(el => {
        const permission = permissions.find(perm => perm.key === el.key);
        el.selected = permission?.value !== false || this.isAdmin;
      })

      // Rooms 
      let rooms = this.permissionsService.getPermissionSync(PERMISSIONS.ROOMS, projectId, this.editableRooms.map(el => el.id));
      this.editableRooms = this.editableRooms.filter(el => rooms.includes(el.id));

      // Sensors
      let disabledSensors = this.permissionsService.getPermissionSync(PERMISSIONS.SENSORS, projectId, []);
      this.editableValues = this.editableValues.filter(el => !disabledSensors.includes(el.type))
    }
  }

  getEditableValuesAndTemplates(permissions, res) {
    const editable = [];
    const rooms = [];

    res.data.rooms.forEach(room => {
      rooms.push({id:room.uuid, name:room.name, selected: true});
      room.sensors.forEach(sensor => {
        if (sensor.isEditable && !editable.includes(sensor.type)) {
          editable.push(sensor.type);
        }
      })
    });

    // Rooms
    this.editableRooms = rooms;

    // Sensors
    const notEditableValues = permissions.find((el) => el.key === PERMISSIONS_KEYS.sensors)?.value || [];
    this.editableValues = editable
      .filter((el) => !notEditableValues.includes(el) || this.permissionsService.role === USER_ROLES.ADMIN)
      .map(item => {
      return {
        type: item,
        selected: true
      }
    })
  }

  setActive(i, type, event) {
    if (!this.isAdmin) {
      // User can't edit permissions
      return;
    }

    if(event.target.nodeName !== 'INPUT') {
      event.preventDefault();
    }
    event.stopPropagation();
    switch (type) {
      case 'setting':
        this.projectSettings[i].selected = !this.projectSettings[i].selected;
        break;
      case 'value':
        this.editableValues[i].selected = !this.editableValues[i].selected;
        break;
      case 'room':
        this.editableRooms[i].selected = !this.editableRooms[i].selected;
        break;
    }
  }

  createLink() {
    // Rooms
    const editableRoomsMetaObj = {
      key: PERMISSIONS_KEYS.rooms,
      type: FULL_PERMISSIONS.find(el => el.key === PERMISSIONS_KEYS.rooms).type
    };

    // Sensors
    const editableValuesMetaObj = {
      key: PERMISSIONS_KEYS.sensors,
      type: FULL_PERMISSIONS.find(el => el.key === PERMISSIONS_KEYS.sensors).type
    };

    // Check box
    const projectSettings = this.projectSettings.map(setting => {
      return {
        value: setting.selected,
        key: setting.key,
        type: setting.type
      }
    });

    const selectedRooms = this.editableRooms.filter(el => el.selected).map(el => el.id);
    const selectedValues = this.editableValues.filter(value => !value.selected).map(v => v.type);
    const data = {
      data: {
        permissions: [
          {
            ...editableRoomsMetaObj,
            value: [...selectedRooms]
          },
          {
            ...editableValuesMetaObj,
            value: [...selectedValues]
          },
          ...projectSettings
        ]
      }
    };

    this.shareProjectService.createLink(this.data.projectId, data).subscribe(res => {
      this.dialogRef.close();
      this.dialog.open(LinkCreatedDialogComponent, {
        data: res.shareLink
      });
    });
  }
}
