<ul class="templates-list" #templateList>
  <div *ngFor="let template of templatesList; let i = index" class="d-flex flex-column item-wrapper">
    <li
      class="templates-list-item d-flex"
      [ngClass]="{'selected': template.selected}"
      (click)="setActiveTemplate(template)"
    >
      <img class="template-icon column-centered" *ngIf="template.icon" src="{{template.icon}}" alt="">
      <mat-icon class="template-icon column-centered" *ngIf="template.iconLocal" [svgIcon]="template.iconLocal"></mat-icon>
      <!--<mat-icon class="template-icon" *ngIf="template.icon" [svgIcon]="template.icon"></mat-icon>-->
      <span class="name column-centered" [ngClass]="{'default-templates': template.default}">{{template.name}}</span>
      <div class="actions" *ngIf="isCustomTemplates">
        <mat-icon (click)="goToCustomizationPage(template)" svgIcon="edit" class="edit-template"></mat-icon>
        <mat-icon (click)="deleteTemplete(template, $event)" svgIcon="trash"></mat-icon>
      </div>
    </li>
    <div class="apply-template"
         *ngIf="template.selected"
         [ngStyle]="{'align-self': getTemplateStyle(i) }"
         [style.width]="containerWidth"
    >
      <div class="templates-list-section-header">
        <span class="apply-template-title">{{'set_for' | translate}}</span> 
        <button (click)="onSelectAllRooms()"> select all</button>
      </div>
      <ul class="apply-template-rooms">
        <li *ngFor="let room of rooms" class="room-item mt-3" [hidden]="!room.hasEditableSensor">
          <label [for]="'room' + '-' + room.id" class="d-flex">
            <input [checked]="room.selected"
                   (change)="setActiveRoom(room)"
                   type="checkbox"
                   [id]="'room' + '-' + room.id"
                   #checkbox
            >
            <span class="room-item-name">{{room.name}}</span>
          </label>
        </li>
      </ul>
      <div class="templates-list-section-header">
        <span class="apply-template-title mt-3">{{'set_on' | translate}}</span>
        <button (click)="onSelectAllDays()"> select all</button> 
      </div>
      <div class="d-flex flex-wrap">
        <ul class="days-selection">
          <li class="days-selection-item mt-3"
              *ngFor="let day of days; let ind = index" [ngClass]="{'selected': day.selected}"
              (click)="selectDay(ind)"
          >
            {{('day_' + day.name).toLowerCase() | translate}}
          </li>
        </ul>
        <button class="cancel-button" (click)="cancelApplyTemplateTo(template)">
          {{'cancel' | translate}}
        </button>
        <button class="apply-button" (click)="applyTemplateTo(template)">{{'apply' | translate}}</button>
      </div>
    
    </div>
  
  </div>
  <li class="templates-list-item add-template" *ngIf="isCustomTemplates" (click)="goToCustomizationPage()">
    <mat-icon svgIcon="custom-template"></mat-icon>
    <span class="name" [ngClass]="{'custom-templates': isCustomTemplates}">+ {{'add_template' | translate}}</span>
  </li>

</ul>
