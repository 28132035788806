import {Injectable} from '@angular/core';
import {Observable, Subject, fromEvent, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  resize$ = fromEvent(window, 'resize') as Observable<any>;
  innerWidth = new BehaviorSubject(window.innerWidth);
  innerWidth$ = this.innerWidth as Observable<number>;

  constructor() {
    this.resize$.subscribe(e => this.innerWidth.next(e.target.innerWidth))
  }

}