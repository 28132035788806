import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {ResizeService} from '../../../core/services/resize/resize.service';
import {TemplatesService} from '../../../core/services/templates/templates.service'
import {AlertService} from '../../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../core/services/projects/projects.service';
import {Subject} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
const ITEM_WIDTH = 400;
const MARGIN = 16;

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent implements OnInit, AfterViewInit {

  @Input() templatesList = [];
  @Input() customTemplates = [];
  @Input() isCustomTemplates = false;
  @Input() rooms = [];
  @Input() selectedSensor;
  @ViewChild('templateList') templateList;
  @Output() onDeleteTemplate = new EventEmitter<boolean>();

  itemsPerRow: number;
  containerWidth: string;
  activeProject;

  days = [
    {name: 'Monday', selected: false},
    {name: 'Tuesday', selected: false},
    {name: 'Wednesday', selected: false},
    {name: 'Thursday', selected: false},
    {name: 'Friday', selected: false},
    {name: 'Saturday', selected: false},
    {name: 'Sunday', selected: false},
  ];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private resizeService: ResizeService,
    private templatesService: TemplatesService,
    private alertService: AlertService,
    private projectsService: ProjectsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getDay();
  }

  ngAfterViewInit() {
    this.resizeService.innerWidth$.subscribe(() => {
      if (this.templateList) {
        setTimeout(() => {
          const {width} = getComputedStyle(this.templateList.nativeElement);
          this.itemsPerRow = Math.floor(+width.split('px')[0] / (ITEM_WIDTH + MARGIN * 2));
        }, 100)
      }
    });
    if (localStorage.getItem('templateSource')) {
      localStorage.removeItem('templateSource');
    }
  }

  private getDay() {
    const date = new Date();
    const day = date.getDay();
    this.days[day - 1].selected = true;
  }

  setActiveTemplate(item): void {
    this.resetAll(this.customTemplates);
    this.resetAll(this.templatesList);
    const isSelected =  item.selected;
    item.selected = !isSelected;
  }

  selectDay(ind: number): void {
    this.days[ind].selected = !this.days[ind].selected;
  }

  onSelectAllRooms() {
    const hasNotSelected = this.rooms.some(el => !el.selected);
    this.selectAll(hasNotSelected)
  }

  onSelectAllDays() {
    const hasNotSelected = this.days.some(el => !el.selected);
    this.days.forEach(el => el.selected = hasNotSelected);
  }

  private selectAll(isChecked) {
    this.rooms = this.rooms.map(room => {
      return {
        ...room,
        selected: isChecked
      }
    })
  }

  private resetAll(items) {
    items.forEach(i => i.selected = false);
  }

  getTemplateStyle(index: number): string {
    let flexAlign;
    if (this.itemsPerRow) {
      if (this.itemsPerRow === 1) {
        flexAlign = 'flex-start';
      } else if (index === 0) {
        flexAlign = 'flex-start';
      } else if (this.itemsPerRow === 2 && index % 2 !== 0) {
        flexAlign = 'flex-end';
      } else if (this.itemsPerRow > 2 && index % 2 === 0) {
        flexAlign = 'flex-end';
      }
    }
    return flexAlign;
  }

  setActiveRoom(room) {
    room.selected = !room.selected;

    if (room.name === 'All' && room.selected) {
      this.selectAll(true)
    } else if (room.name === 'All' && !room.selected) {
      this.selectAll(false);
    }
    if (room.name !== 'All' && !room.selected) {
      const allItemCheckbox = this.rooms.find(item => item.name === 'All');
      allItemCheckbox.selected = false;
    }
  }

  getSelectedRoomsIds() {
    const currentSelectedRooms = [];
    this.rooms.map(room => {
      if (room.selected) {
        currentSelectedRooms.push(room.id)
      }
    });
    return currentSelectedRooms;
  }

  getSelectedDays() {
    const currentSelectedDays = [];
    this.days.map(day => {
      if (day.selected) {
        currentSelectedDays.push(day.name.toLowerCase())
      }
    });
    return currentSelectedDays;
  }

  applyTemplateTo(template) {
    const data = {
      templateId: template.id,
      rooms: this.getSelectedRoomsIds(),
      days: this.getSelectedDays()
    };
    this.templatesService.applyTemplate({data: data}).subscribe(
      (response) => {
        if (response) {
          this.alertService.openInfoModal(
              this.translate.instant('successfully_updated'),
              this.translate.instant('success'));
          template.selected = false;
        }
      },
      (err) => {
        this.alertService.openErrorModal('Error', err.error.error.message);
      }
    );
  }

  cancelApplyTemplateTo(template) {
    template.selected = false;
  }

  deleteTemplete(template, event) {
    event.stopPropagation();
    this.alertService.openConfirmModal(this.translate.instant('delete_template'),
        this.translate.instant('delete_template_confirm'),
        this.translate.instant('delete'),
        this.translate.instant('close'),
        (answer: boolean) => {
        if (answer) {
          this.templatesService.deleteTemplate(template.id).subscribe(
            (response) => {
              if (response) {
                this.onDeleteTemplate.emit(true);
                this.alertService.openInfoModal(
                    this.translate.instant('successfully_removed'),
                    this.translate.instant('success')
                );
                template.selected = false;
              }
            },
            (err) => {
              this.alertService.openErrorModal('Error', err.error.error.message);
            }
          );
          return;
        }
      });
  }

  goToCustomizationPage(template?) {
    if (template) {
      this.templatesService.setTemplateForCustomization(template);
      this.router.navigate(['/templates/custom', template.sensorType,  template.id]);
    } else {
      this.router.navigate(['/templates/custom', this.selectedSensor.type]);
    }
  }
}
