import {Injectable} from '@angular/core';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications = [
    {title: 'Notification title', description: 'Notification additional text for explain', status: 'active'},
    {title: 'Notification title', description: 'Notification additional text for explain', status: 'warn'},
    {title: 'Notification title', description: 'Notification additional text for explain', status: 'inactive'},
  ];

  constructor() {
  }

  getNotifications() {
    return of(this.notifications);
  }
}
