import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {UsersService} from '../../../core/services/users/users.service';
import {ModalWindowSuccessComponent} from '../modal-windows-success/modal-window-success.component';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {HistoryService} from '../../../core/services/history/history.service';
import {takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../core/services/projects/projects.service';
import {Subject} from 'rxjs';
import {AlertService} from '../../../core/services';




@Component({
  selector: 'app-modal-window-for-download-data',
  templateUrl: './modal-window-for-download-data.component.html',
  styleUrls: ['./modal-window-for-download-data.component.scss']
})
export class ModalWindowForDownloadDataComponent implements OnInit {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  private destroy$: Subject<boolean> = new Subject<boolean>();
  currentSession: string;
  constructor(
    private historyService: HistoryService,
    private usersService: UsersService,
    private projectsService: ProjectsService,
    public dialog: MatDialog,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: {startDate: string, endDate:string}
  ) {
  }

  ngOnInit(): void {
    this.getProjectUser()
    this.getDataByActiveProject()
  }

  getProjectUser() {
    this.usersService.getCurrentUser().subscribe(res => {
      this.emailFormControl.setValue(res.email);
    })
  }

  getDataByActiveProject() {
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.currentSession = res.rooms[0].projectUuid;
    })
  }
  sentEmail() {
    const rawData = {
      data: {
        projectUuid: this.currentSession,
        startTime: this.data.startDate,
        endTime: this.data.endDate,
        email: this.emailFormControl.value
      }
    }
    this.historyService.postRoomHistoryData(rawData).subscribe(
      result => {
        if (result) {
          this.dialog.open(ModalWindowSuccessComponent, {
            height: '241px'
          })
        }
      },
      err => {
        this.alertService.openErrorModal('Alert', err.error.error.message);
      }
    )
  }
}
