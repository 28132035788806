import {Component, OnInit} from '@angular/core';
import {IconService} from './core/services/icon/icon.service';
import {ProjectsService} from './core/services/projects/projects.service';
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionsService} from "./core/services/permissions/permissions.service";
import {TranslateService} from "@ngx-translate/core";
import {AlertService, AuthService} from "./core/services";
import {AuthGuard} from "./core/guards/auth.guard";
import {ShareProjectService} from "./core/services/share-project/share-project.service";

const SHARE_LINK_LENGTH = 36;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'rotazone_web_client';

  constructor(
    private iconService: IconService,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private translate: TranslateService,
    private authService: AuthService,
    private authGuard: AuthGuard,
    private alertService: AlertService,
    private shareProject: ShareProjectService,
    private permissionService: PermissionsService,
    private router: Router
  ) {
    const lang = localStorage.getItem('lang');
    // this language will be used as a fallback when a translation isn't found in the current language.
    translate.setDefaultLang(lang || 'en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(lang || 'en');
  }

  ngOnInit() {
    const sharedLink = window.location.href.split('/share-link/')[1]
    if (sharedLink) {
      if (sharedLink.length === SHARE_LINK_LENGTH) {
        this.shareProject.shareProject(sharedLink).subscribe(() => {
          this.permissionService.getPermissions().subscribe();
          this.projectsService.getProjectsList();
          this.alertService.openInfoModal('', 'Project has been successfully imported');
          this.router.navigate(['/dashboard']);
        })

      }
    }

    // Quick CLIM-753 fix
    if (this.authService.isLoggedIn) {
      this.permissionsService.getPermissions().subscribe();
    }

    this.iconService.registerIcons();
    this.translate.onLangChange.subscribe(res => {
      localStorage.setItem('lang', res.lang);
    })

  }
}
