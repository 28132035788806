import {Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard-room-filter',
  templateUrl: './dashboard-room-filter.component.html',
  styleUrls: ['./dashboard-room-filter.component.scss']
})
export class DashboardRoomFilterComponent implements OnInit, OnChanges {

  @Input() roomDashboardFilterList: any;
  @Input() selectedItems: any = [];
  @Input() maxSelectedItems: number = 0;
  @Input() isAllVisible: Boolean = false;
  @Input() isAllCircleVisible: Boolean = false;
  @Output() setFilterRoomValue = new EventEmitter();
  @Output() selectedItemsRoomValue = new EventEmitter();
  @ViewChildren('roomfilter') roomfilter: QueryList<any>;
  isAllSelected: Boolean = false;
  
  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    const items = changes.roomDashboardFilterList.currentValue.filter(el => el.selected);
    this.setSelecedItems(items);
  }

  private setSelected(isChecked) {
    this.roomDashboardFilterList.map((room) => {
      return room.selected = isChecked;
    });
  }

  private setSelecedItems(items) {
    this.selectedItems = items;
    this.isAllSelected = this.roomDashboardFilterList.length === this.selectedItems.length;
    this.notify();
  }

  setActive(item) {
    if (!item) return;
    item.selected = !item.selected;

    // Append or remove
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(obj => obj !== item);
    }

    // Deselect previous, if maxSelectedItems is > 0
    if (this.maxSelectedItems > 0 && this.selectedItems.length > this.maxSelectedItems) {
      for (let i = 0; i < this.selectedItems.length - this.maxSelectedItems; i++) {
        this.selectedItems[i].selected = false;
      }
      this.selectedItems = this.selectedItems.slice(-this.maxSelectedItems);
    }

    this.isAllSelected = this.roomDashboardFilterList.length === this.selectedItems.length;
    this.notify();
  }

  selectAll() {
    this.isAllSelected = !this.isAllSelected;

    if (this.isAllSelected) {
      this.setSelected(true);
      this.selectedItems = this.roomDashboardFilterList;
    } else {
      this.setSelected(false);
      this.selectedItems = [];
    }
    this.notify();
  }

  private notify() {
    this.setFilterRoomValue.emit(this.roomDashboardFilterList);
    this.selectedItemsRoomValue.emit(this.selectedItems);
  }
}
