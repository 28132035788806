<div class="dashboard-room-scheduler col-12">
  
  <div class="col-12 d-flex pl-0 pr-0 dashboard-room-scheduler-top-block justify-content-space-between">
    <div>
      <div class="dashboard-room-scheduler-breadcrumbs">
        <span class="pointer" routerLink="/dashboard">Dashboard </span>
        <span> &gt; </span>
        <span class="main-color">{{roomScheduler?.name}} {{('room_property' | translate)}}</span>
      </div>
    </div>
    <div class="dashboard-room-scheduler-action-btns-block hide">
      <div class="dashboard-room-scheduler-action-btns">
        <button mat-stroked-button color="primary" class="action-btn">
          <span>Reset</span>
        </button>
      </div>
    </div>
  </div>
  
  <div class="col-12 d-flex p-0">
    <div class="col-8 pl-0">
      <div class="dashboard-room-scheduler-white-box d-flex">
        <div class="d-flex w-100" *ngIf="['valve', 'heating', 'heatingFlow'].indexOf(propertyType) > -1">
          <mat-icon [svgIcon]="sensorSelectedIcon.icon" class="icon select-template-icon" *ngIf="sensorSelectedIcon"></mat-icon>
          <mat-select placeholder="Select sensor" [(value)]="sensorSelected" >
            <mat-select-trigger class="text-capitalize">{{sensorSelected}}</mat-select-trigger>
            <mat-option *ngFor="let sensor of roomScheduler?.schedulers; let i = index" class="text-capitalize"
                        [value]="sensor.title" (click)="setActiveSchedulerSensor(sensor)">
              <mat-icon matSuffix  class="icon fit-in-option">
                <mat-icon [svgIcon]="sensor.icon" class="icon"></mat-icon>
              </mat-icon>
              {{sensor.title}}
            </mat-option>
          </mat-select>
        </div>
        
        <label class="text-capitalize" *ngIf="['valve', 'heating', 'heatingFlow'].indexOf(propertyType) <= -1">
          <mat-icon [svgIcon]="propertyType" class="icon"></mat-icon>
          {{propertyType}}
        </label>
      </div>
    </div>
    <div class="col-4 pr-0">
      <div class="dashboard-room-scheduler-white-box">
        <label>{{'select_template' | translate}}:</label>
        <img src="{{dayTemplate?.template?.icon}}" class="select-template-icon" *ngIf="dayTemplate?.template?.icon">
        <mat-select placeholder="Select sensor" class="w-50" [value]="dayTemplate.template.name" *ngIf="dayTemplate"  [compareWith]="compareObjects">
          <mat-select-trigger class="text-uppercase">{{dayTemplate.template.name}}</mat-select-trigger>
          <mat-option *ngFor="let template of templatesList;let i = index" [value]="template?.name"
                      (click)="setActiveSensorTemplate(template)" class="text-uppercase">
            <img matSuffix class="select-template-icon" *ngIf="template?.icon" src="{{template.icon}}" alt="">
            {{template?.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex p-0" >
    <div class="dashboard-room-scheduler-graph" #graphWrapper>
      <app-graph [disableInteractions]="templatePermissions?.includes(propertyType)" [(changed)]="templateChanged" [graphContainerWidth]="graphWrapper.clientWidth" #graph></app-graph>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-between p-0 dashboard-room-scheduler-days">
    <div class="col-auto pl-0">
      <div class="dashboard-room-scheduler-days-filter" *ngIf="daysList">
        <ul class="d-flex align-items-center text-capitalize h-100 ">
          <li *ngFor="let day of daysList; let i = index"
              class="dashboard-room-scheduler-days-filter-item scheduler-days-filter-item  text-capitalize"
              [ngClass]="day.selected ? 'active' : ''">
            <label class="select-item" (click)="setActiveDay(day)">
              <span>{{('day_' + day.name).toLowerCase() | translate}}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-5 pr-0 dashboard-room-scheduler-action-btns-block" *ngIf="!templatePermissions?.includes(propertyType)">
      <div class="dashboard-room-scheduler-action-btns justify-content-flex-end">
<!--        {{dayTemplate | json}}-->
        <button mat-stroked-button color="primary" class="action-btn hide">
          <span>{{'cancel' | translate}}</span>
        </button>
        <button (click)="updateExistingTemplate()" *ngIf="templateChanged && !dayTemplate?.template.default && !dayTemplate?.template.isActive" mat-flat-button color="primary" class="action-btn">
          <span>{{'update_existing' | translate}}</span>
        </button>
        <button (click)="saveAsNewTemplate()" *ngIf="templateChanged" mat-flat-button color="primary" class="action-btn">
          <span>{{'save_as_new' | translate}}</span>
        </button>
        <button (click)="applyTemplate()" *ngIf="!templateChanged" mat-flat-button color="primary" class="action-btn">
          <span>{{'apply' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
