<div class="dashboard-rooms" *ngIf="roomDashboardFilterList.length">
  <div *ngFor="let room of roomDashboardFilterList | FilterBySensor:filterBySensorType;let i = index"
       class="room-item">
    <div *ngIf="room.selected && room.roomId !== 'all' && room.sensors && room.sensors.length">
      <div class="room-section" [hidden]="room.isEditMode">
        <div class="room-section-title">
          <span class="title">{{room.name}} {{('room_properties' | translate)}}:</span>
          <span class="edit" (click)="setEditMode(room)" *ngIf = "(!noneEditableSensors && roomDashboardFilterList[i].sensors[0].isEditable) || filterBySensorType==='all'">
            <mat-icon svgIcon="edit"></mat-icon>
        </span>
        </div>
        <div class="room-sensors-section">
          <div class="room-sensor" *ngFor="let property of room.sensors; let i = index"
               [ngClass]="{'pointer': property.isEditable}">
  
            <div (click)="goToScheduler(room, property)" class="room-sensor-item">
              <div class="icon-block">
                <mat-icon svgIcon="{{property.icon}}"></mat-icon>
              </div>
              <div class="room-section-values">
                <div class="room-section-values-actual">
                  <span class="title">{{'actual' | translate}}</span>
                  <span class="value"> {{property.value || '-'}} {{(property.value && property.units) ? property.units : ''}}
                  </span>
                </div>
                <div class="room-section-values-set" [hidden]="!property.isEditable">
                  <span class="title">{{'set' | translate}}</span>
                  <span class="value"
                        *ngIf="room.setValues && room.setValues[property.type] && !room.isEditMode ? setPropertyValue(property, room.setValues[property.type]) : false"
                        >{{property.set}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <p>{{room | json}}</p>-->
      
      <div class="room-section edit-mode" [hidden]="!room.isEditMode">
        <div class="edit-mode-room-section-title">
          <span class="title">{{room.name}} {{('room_properties' | translate)}}:</span>
          <div>
            <span class="pointer save-edit-property" (click)="saveEditingMode(room)">Save</span>
            <span class="pointer cancel-edit-property" (click)="cancelEditingMode(room)">Cancel</span>
          </div>
        </div>
        
        <div class="edit-mode-room-sensors-section">
          <div class="room-sensor-time">
            <div  class="room-sensor-time-item">
              <mat-icon svgIcon="alarm"></mat-icon>
              <span class="current-time">
                <span *ngIf="time.hour < 10">0</span>{{time.hour}}
                :
                <span *ngIf="time.minute < 10">0</span>{{time.minute}}
                
              </span>
              <div class="room-sensor-timer">
                <ngb-timepicker [formControl]="ctrlTime" class="room-sensor-timer-input-block" (change)="onTimeChange($event)"></ngb-timepicker>
              </div>
              
            </div>
          </div>
          
          <div class="room-sensor-slider-block" *ngFor="let property of room.sensors; let i = index"
               [hidden]="!property.isEditable || property.type == 'co2' || property.type == 'voc' || noPermission(property.type)">
            <div class="room-sensor-slider-block-title">
              <mat-icon svgIcon="{{property.icon}}"></mat-icon>
              <span>{{property.set}}</span>
            </div>
            <div class="room-sensor-slider">
              <mat-icon (click)="decreasePropValue(property, room)">remove</mat-icon>
              <mat-slider
                [max]="property.range[1]"
                [min]="property.range[0]"
                [step]="0.01"
                (change)="updatePropertyValue(property, room, $event)"
                (input)="updatePropertyValue(property,room, $event)"
                class="edit-mode-slider"
                [(ngModel)]="property.set">
              </mat-slider>
              <mat-icon (click)="increasePropValue(property, room)">add</mat-icon>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  
  </div>
</div>
