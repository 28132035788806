import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment-timezone';
import {switchMap, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {v4 as uuidv4} from 'uuid';

import {dayEnum, eqaulSensorsInRoom, SchedulerTemplate, Template} from '../../../../models';
import {schedulerValueToUnit, getSensorRange, RANGE_SENSORS, Sensor} from '../../../../models/sensor';

import {GraphComponent} from '../../../../shared/components/graph/graph.component';
import {SchedulerActionsComponent} from '../../../../shared/components/scheduler-actions/scheduler-actions.component';

import {SchedulerUtils} from '../../../../core/services/scheduler/schedulerUtils';
import {ProjectsService} from '../../../../core/services/projects/projects.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {AlertService} from '../../../../core/services';
import {SchedulerService} from '../../../../core/services/scheduler/scheduler.service';
import * as _ from 'lodash';
import {PERMISSIONS} from "../../../../models/permission.model";
import {of, Subject} from "rxjs";
import {PermissionsService} from "../../../../core/services/permissions/permissions.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-dashboard-room-scheduler',
    templateUrl: './dashboard-room-scheduler.component.html',
    styleUrls: ['./dashboard-room-scheduler.component.scss']
})

export class DashboardRoomSchedulerComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('graph') graph: GraphComponent;

    daysList = [
        {name: 'monday', selected: false, dayId: 0},
        {name: 'tuesday', selected: false, dayId: 1},
        {name: 'wednesday', selected: false, dayId: 2},
        {name: 'thursday', selected: false, dayId: 3},
        {name: 'friday', selected: false, dayId: 4},
        {name: 'saturday', selected: false, dayId: 5},
        {name: 'sunday', selected: false, dayId: 6}
    ];

    templatesList: Template[] = [];
    allTemplatesList;
    sensorSelected: string;
    sensorSelectedIcon;
    projectId: number;
    roomScheduler;
    activeProject = localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')) : '';
    daysOfTheWeek: number;
    dayTemplate: SchedulerTemplate;
    propertyType: Sensor;
    templateChanged = false;
    roomId: string;
    roomEditableSensors: [];
    templatePermissions = [];
    private destroy$: Subject<boolean> = new Subject<boolean>();


    compareObjects(object1: any, object2: any) {
        return object1 && object2 && object1.name == object2.name;
    }

    compareSensorsObjects(object1: any, object2: any) {
        return object1 && object2 && object1.title == object2.title;
    }


    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private projectsService: ProjectsService,
        private templatesService: TemplatesService,
        private schedulerUtils: SchedulerUtils,
        private schedulerService: SchedulerService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private permissionsService: PermissionsService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.projectId = +this.activatedRoute.snapshot.paramMap.get('projectId');
        this.roomId = this.activatedRoute.snapshot.paramMap.get('roomId');
        this.propertyType = this.activatedRoute.snapshot.paramMap.get('propertyType') as Sensor;
        this.daysOfTheWeek = moment().tz(this.activeProject.timeZoneId).isoWeekday() - 1;
        this.getRoomSensors();
        this.getTemplateList();
        const getTemplateByActiveDayByInterval = setInterval(() => {
            if (this.roomScheduler?.currentScheduler) {
                this.setActiveDay(this.daysList[this.daysOfTheWeek]);
                clearInterval(getTemplateByActiveDayByInterval);
            }
        }, 500);

        setTimeout(() => {
            clearInterval(getTemplateByActiveDayByInterval);
        }, 120000);

        this.projectsService.activeProject$.pipe(
            takeUntil(this.destroy$),
            switchMap(project => {
                if (project) {
                    // if (this.projectId !== project.project.id) {
                    //     this.router.navigate(['/dashboard'])
                    // }
                    return this.permissionsService.getPermission(PERMISSIONS.SENSORS, project.project.id);
                } else {
                    return of(null)
                }
            })).subscribe(res => {
            this.templatePermissions = res;
        })

    }


    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewInit() {
        if (this.dayTemplate) {
            this.updateGraph(this.dayTemplate.template);
        }
    }

    getRoomSensors() {
        this.projectsService.getActiveProjectSensors(this.activeProject.id).subscribe(sensors => {
            if (sensors) {
                const roomData = sensors.data.rooms.filter(r => r.id.toString() === this.roomId)[0];
                this.roomEditableSensors = (roomData?.sensors?.length) ? roomData.sensors.filter(s => s.isEditable) : [];
                this.getSchedulerByRoom();
            }
        });
    }

    getSchedulerByRoom() {
        this.schedulerService.getScheduler(this.projectId, [this.roomId]).subscribe(resp => {
            this.roomScheduler = resp.rooms[0];
            const groupTypes = ['valve', 'heating', 'heatingFlow'];
            const isGroupType = groupTypes.includes(this.propertyType);
            // filter schedulers by selected property type
            this.roomScheduler.schedulers = this.roomScheduler.schedulers.filter(scheduler => {
                if (isGroupType) {
                    return scheduler.type === 'valve' || scheduler.type === 'heating' || scheduler.type === 'heatingFlow';
                } else {
                    return scheduler.type === this.propertyType;
                }
            });
            if (this.roomEditableSensors && isGroupType) {
                this.roomEditableSensors.forEach(roomSensor => {
                    if (roomSensor['type'] === 'valve' || roomSensor['type'] === 'heating' || roomSensor['type'] === 'heatingFlow') {
                        this.roomScheduler.schedulers.push({
                            type: roomSensor['type'],
                            templates: this.roomScheduler.schedulers[0].templates.filter(t => t.template.sensorType === roomSensor['type'])
                        });
                    }
                });
                this.roomScheduler.schedulers.shift();
                this.roomScheduler.schedulers = _.uniqBy(this.roomScheduler.schedulers, 'type');
            }

            // map schedulers title and icon
            this.roomScheduler.schedulers.map(scheduler => {
                scheduler = this.setSensorIconAndTitle(scheduler);
                return scheduler;
            });

            if (groupTypes.includes(this.propertyType)) {
                this.roomScheduler.currentScheduler = this.roomScheduler.schedulers.find(e => {
                    return ['valve', 'heating', 'heatingFlow'].indexOf(e.type) > -1
                });

                // this.roomScheduler.currentScheduler.templates = this.roomScheduler.currentScheduler.templates.filter( t => t.template.sensorType === this.propertyType);
            } else {
                this.roomScheduler.currentScheduler = this.roomScheduler.schedulers.find(e => e.type === this.propertyType);
            }

            this.sensorSelected = this.roomScheduler.currentScheduler.title;
            this.sensorSelectedIcon = this.roomScheduler.currentScheduler;

            if (this.dayTemplate?.template) {
                this.getTemplateByDay(this.daysOfTheWeek);
                this.updateGraph(this.dayTemplate.template);
            }
        })
    }

    getTemplateList() {
        this.templatesService.getTemplates().subscribe(resp => {
            const groupTypes = ['valve', 'heating', 'heatingFlow'];

            // filter schedulers by selected property type
            this.allTemplatesList = resp.data.filter(t => {
                const isGroupType = groupTypes.includes(this.propertyType);
                if (isGroupType) {
                    return t.type === 'valve' || t.type === 'heating' || t.type === 'heatingFlow';
                } else {
                    return t.type === this.propertyType;
                }
            });
            this.templatesList = this.allTemplatesList.filter(t => t['type'] === this.propertyType)[0].templates;

            if (this.dayTemplate?.template?.icon) {
                this.dayTemplate.template.icon = this.templatesList.find(template => this.dayTemplate.template.id === template.id) ?
                    this.templatesList.find(template => this.dayTemplate.template.id === template.id).icon : null;
            }
        })
    }

    getTemplateByDay(day): SchedulerTemplate {
        this.dayTemplate = _.cloneDeep(this.roomScheduler.currentScheduler.templates.find(template => {
            return dayEnum[template.day] === day;
        }));


        if (this.templatesList && this.templatesList.length && this.dayTemplate) {
            this.dayTemplate.template.icon = this.templatesList.find(template => this.dayTemplate.template.id === template.id) ?
                this.templatesList.find(template => this.dayTemplate.template.id === template.id).icon : null;
        }

        this.daysList.forEach(selectedDay => {
            selectedDay.selected = selectedDay.dayId === day;
        });
        return this.dayTemplate;
    }

    setSensorIconAndTitle(sensor) {
        switch (sensor.type) {
            case 'voc':
                sensor.icon = 'voc';
                sensor.title = 'voc';
                break;
            case 'humidity':
                sensor.icon = 'humidity';
                sensor.title = 'humidity';
                break;
            case 'airflow':
                sensor.icon = 'airflow';
                sensor.title = 'air flow';
                break;
            case 'heating':
                sensor.icon = 'heating';
                sensor.title = 'temperature';
                break;
            case 'heatingFlow':
                sensor.icon = 'heating-flow';
                sensor.title = 'heating flow';
                break;
            case 'heatingTemp':
                sensor.icon = 'heating-temp';
                sensor.title = 'heating temp';
                break;
            case 'temperature':
                sensor.icon = 'heating';
                sensor.title = 'temperature';
                break;
            case 'valve':
                sensor.icon = 'valve';
                sensor.title = 'valve';
                break;
            case 'light':
                sensor.icon = 'light';
                sensor.title = 'light';
                break;
            case 'alarm':
                sensor.icon = '';
                sensor.title = '';
                break;
            case 'co2':
                sensor.icon = 'co2';
                sensor.title = 'co2';
                break;
            case 'noiseLevel':
                sensor.icon = 'noise-level';
                sensor.title = 'noise level';
                break;
            case 'waterTemperature':
                sensor.icon = 'water-temperature';
                sensor.title = 'water temperature';
                break;
            default:
                sensor.icon = '';
                sensor.title = '';
                break;
        }
        return sensor.icon;
    }

    setActiveDay(day) {
        if (!day) return;
        this.daysList.map((d) => {
            return d.selected = false;
        });
        day.selected = !day.selected;

        const groupTypes = ['valve', 'heating', 'heatingFlow'];
        const isGroupType = groupTypes.includes(this.propertyType);
        if (isGroupType && this.roomScheduler?.schedulers) {
            this.roomScheduler.schedulers.forEach(e => {
                e.templates.find(t => {
                    if (day.name === t.day) {
                        this.roomScheduler.currentScheduler = e;
                        return;
                    }
                });
            });
            this.sensorSelected = this.roomScheduler.currentScheduler.title;
            this.sensorSelectedIcon = this.roomScheduler.currentScheduler;
            this.templatesList = this.allTemplatesList.filter(t => t['type'] === this.roomScheduler.currentScheduler.type)[0]['templates'];
        }

        const template = this.getTemplateByDay(day.dayId);
        this.updateGraph(template.template);
    }

    updateGraph(template: Template) {
        if (!this.graph) return;
        const range = getSensorRange(template.sensorType as Sensor);

        this.graph.setSensorRange({x: 0, y: range.yMin, width: 24, height: range.yMax - range.yMin });

        // Set value to Unit graph converter function
        let sensorCount = eqaulSensorsInRoom(template.sensorType as Sensor, +this.roomId, this.activeProject);
        let toUnit = schedulerValueToUnit(template.sensorType as Sensor, sensorCount);
        this.graph.setValueToUnitFunc(toUnit);

        if (RANGE_SENSORS.includes(template.sensorType as Sensor)) {
            this.graph.setRangePoints(template.values.map(e => {
                    return {x: Number(e[0]) / 3600, yMin: Number(e[1]), yMax: Number(e[2])}
                }
            ))
        } else {
            this.graph.setPoints(template.values.map(e => {
                    return {x: Number(e[0]) / 3600, y: Number(e[1])}
                }
            ))
        }
    }

    setActiveSchedulerSensor(sensor): void {
        if (!sensor) return;
        this.roomScheduler.currentScheduler = sensor;
        this.sensorSelected = sensor.title;
        this.sensorSelectedIcon = sensor;
        this.templatesList = this.allTemplatesList.filter(t => t['type'] === sensor.type)[0]['templates'];
        this.setActiveSensorTemplate(this.templatesList.find(t => !t.default));
    }

    setActiveSensorTemplate(template): void {
        this.updateGraph(template);
        this.dayTemplate.template = template;
    }

    updateExistingTemplate() {
        const dialogRef = this.dialog.open(SchedulerActionsComponent, {
            data: {
                title: 'Update existing template',
                name: this.dayTemplate.template.name,
                action: 'update',
                templatesByDays: this.roomScheduler.currentScheduler.templates
                    .filter(template => template.templateId === this.dayTemplate.template.id),
            }
        });

        dialogRef.afterClosed().subscribe(value => {
            if (value && value.days.length) {
                const selectedDays = value.days.map(day => {
                    if (day.selected) return day.name.toLowerCase()
                });

                const data = {
                    days: selectedDays.filter(Boolean),
                    rooms: [+this.roomId],
                    templateId: null
                };

                let templateValues;

                if (RANGE_SENSORS.includes(this.dayTemplate.template.sensorType)) {
                    templateValues = this.graph.rangePoints.map(p => [(p.x * 3600).toString(), p.yMin.toString(), p.yMax.toString()])
                } else {
                    templateValues = this.graph.points.map(p => [(p.x * 3600).toString(), p.y.toString()])
                }

                const newData = {
                    data: [{
                        projectId: this.projectId,
                        id: this.dayTemplate.template.id,
                        sensorType: this.dayTemplate.template.sensorType,
                        uuid: this.dayTemplate.template.uuid,
                        name: value.name,
                        values: templateValues,
                        isActive: false,
                        projectUuid: this.activeProject.uuid
                    }]
                };

                this.templatesService.createTemplate(newData).pipe(switchMap(res => {
                    data.templateId = res.data[0].id;
                    return this.templatesService.applyTemplate({data})
                })).subscribe(() => {
                    this.getTemplateList();
                    this.getSchedulerByRoom();

                    this.alertService.openInfoModal(
                        this.translate.instant('successfully_updated'),
                        this.translate.instant('success'));
                }, (err) => {
                    this.alertService.openErrorModal('Error', err.error.error.message);
                })
            }
        })
    }

    saveAsNewTemplate() {
        const dialogRef = this.dialog.open(SchedulerActionsComponent, {
            data: {
                title: 'Save as new template',
                name: this.dayTemplate.template.name,
                action: 'save',
                templatesByDays: this.roomScheduler.currentScheduler.templates
                    .filter(template => template.templateId === this.dayTemplate.template.id),
            }
        });
        dialogRef.afterClosed().subscribe(value => {
            if (value && value.days.length) {
                const selectedDays = value.days.map(day => {
                    if (day.selected) return day.name.toLowerCase()
                });
                const data = {
                    days: selectedDays.filter(Boolean),
                    rooms: [+this.roomId],
                    templateId: null
                };
                let templateValues;

                if (RANGE_SENSORS.includes(this.dayTemplate.template.sensorType)) {
                    templateValues = this.graph.rangePoints.map(p => [(p.x * 3600).toString(), p.yMin.toString(), p.yMax.toString()])
                } else {
                    templateValues = this.graph.points.map(p => [(p.x * 3600).toString(), p.y.toString()])
                }

                const newData = {
                    data: [{
                        projectId: this.projectId,
                        id: null,
                        sensorType: this.dayTemplate.template.sensorType,
                        uuid: uuidv4(),
                        name: value.name,
                        values: templateValues,
                        isActive: false,
                        projectUuid: this.activeProject.uuid
                    }]
                };

                this.templatesService.createTemplate(newData).pipe(switchMap(res => {
                    data.templateId = res.data[0].id;
                    return this.templatesService.applyTemplate({data})
                })).subscribe(() => {
                    this.getTemplateList();
                    this.getSchedulerByRoom();
                    this.alertService.openInfoModal(
                        this.translate.instant('successfully_saved'),
                        this.translate.instant('success'));
                }, (err) => {
                    this.alertService.openErrorModal('Error', err.error.error.message);
                })
            }
        })
    }

    applyTemplate() {
        const dialogRef = this.dialog.open(SchedulerActionsComponent, {
            data: {
                title: 'Apply template',
                name: this.dayTemplate.template.name,
                action: 'apply',
                templatesByDays: this.roomScheduler.currentScheduler.templates
                    .filter(template => template.templateId === this.dayTemplate.template.id)
            }
        });
        dialogRef.afterClosed().subscribe(value => {
            if (value && value.days.length) {
                const selectedDays = value.days.map(day => {
                    if (day.selected) return day.name.toLowerCase()
                });
                const dataValues = {
                    templateId: this.dayTemplate.template.id,
                    days: selectedDays.filter(Boolean),
                    rooms: [this.roomId]
                };

                this.templatesService.applyTemplate({data: dataValues}).subscribe(
                    (response) => {
                        if (response) {
                            this.getSchedulerByRoom();
                            this.alertService.openInfoModal(
                                this.translate.instant('successfully_applied'),
                                this.translate.instant('success'));
                        }
                    },
                    (err) => {
                        this.alertService.openErrorModal('Error', err.error.error.message);
                    }
                );
            }
        })
    }
}
