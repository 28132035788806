import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
    AlertService
} from '../../../../core/services';
import {ProjectsService} from '../../../../core/services/projects/projects.service';
import {Subject} from 'rxjs/index';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditProjectNameDialogComponent} from '../../../../shared/components/edit-project-name-dialog/edit-project-name-dialog.component';
import {EditProjectWifiDialogComponent} from '../../../../shared/components/edit-project-wifi-dialog/edit-project-wifi-dialog.component';
import {PermissionsService} from "../../../../core/services/permissions/permissions.service";
import {PERMISSIONS} from "../../../../models/permission.model";
import {TranslateService} from "@ngx-translate/core";
import {HistoryService} from "../../../../core/services/history/history.service";
import {Room} from "../../../../models/room.model";
import {EditProjectAdjustmentsComponent} from "../../../../shared/components/edit-project-adjustments/edit-project-adjustments.component";


@Component({
    selector: 'app-edit-project',
    templateUrl: './edit-project.component.html',
    styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit, OnDestroy {
    project;
    projectId;
    projectAlias: string;
    projectRooms: Room[];
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private projectsService: ProjectsService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private permissionsService: PermissionsService,
        private historyService: HistoryService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.projectId = this.activatedRoute.snapshot.paramMap.get('projectId');
        this.getProjectById();
    }

    getProjectById() {
        this.projectsService.getProjectById(this.projectId).subscribe(resp => {
            if (resp) {
                this.project = resp.project;
                this.projectRooms = resp.rooms;
                this.projectsService.getProjectsAlias({modelUuid: this.project.uuid, aliasType: 'project'})
                    .subscribe(res => {
                        if (res.data[0]) {
                            this.projectAlias = res.data[0].alias + "*"
                        }
                    })
            }
        })
    }

    editProjectName(project) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        dialogConfig.data = {
            id: project.id,
            projectName: project.name,
            uuid: project.uuid,
            isSubmitted: false
        };

        const dialogRef = this.dialog.open(EditProjectNameDialogComponent, dialogConfig);

        dialogRef.componentInstance.onSave.subscribe(updatedData => {
            if (!updatedData) return;
            dialogConfig.data.isSubmitted = true;

            const changeNameSub = this.projectsService.projectChangeName({
                data: {
                    projectUuid: project.uuid,
                    name: updatedData.name
                }
            })

            const subscription = updatedData.isAlias ?
                this.changeProjectAlias(updatedData.alias, project.uuid):
                changeNameSub;


            subscription.subscribe(resp => {
                if (resp) {
                    this.getProjectById();
                    this.alertService.openInfoModal(this.translate.instant('Success'), `Project ${updatedData.isAlias ? 'alias': 'name'} has been updated.`);
                    dialogConfig.data.isSubmitted = false;
                    dialogRef.close();
                    if (!updatedData.isAlias) {
                        this.changeProjectAlias(updatedData.name, project.uuid).subscribe();
                    }
                    this.projectsService.getProjectsList();
                }
            }, (err) => {
                this.alertService.openErrorModal('Error', err.error.error.message);
                dialogConfig.data.isSubmitted = false;
                dialogRef.close();
            });
        });
    }

    private changeProjectAlias(value, projectUuid) {
        return this.projectsService.projectChangeAlias([
            {
                alias: value,
                modelUuid: projectUuid,
                aliasType: 'project'
            }])
    }

    editProjectWiFiSettings(project, type) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        const wifi = (type == 'st' ? project.wifi : project.systemWifi) || { "ssid": "", "password": "" }
        dialogConfig.data = wifi;
        dialogConfig.data.isSubmitted = false;

        const dialogRef = this.dialog.open(EditProjectWifiDialogComponent, dialogConfig);

        dialogRef.componentInstance.onSave.subscribe(updatedData => {
            if (!updatedData) return;

            dialogConfig.data.isSubmitted = true;
            switch (type) {
                case 'ap':
                    this.projectWifiChangeAPSettings(project, updatedData, dialogRef);
                    break;
                case 'st':
                    this.projectWifiChangeSTSettings(project, updatedData, dialogRef);
                    break;
            }
        });
    }

    editProjectAdjustments() {
        this.historyService.getProjectAdjustment(this.project.uuid).subscribe(res => {
            this.dialog.open(EditProjectAdjustmentsComponent, {
                data: {
                    rooms: this.projectRooms,
                    measurements: res[0] || [],
                    projectUuid: this.project.uuid
                }
            });
        })
    }

    projectWifiChangeAPSettings(project, data, dialogRef) {
        this.projectsService.projectWifiChangeAPSettings({
            data: {
                projectUuid: project.uuid,
                ssid: data.ssid,
                password: data.password
            }
        }).subscribe(resp => {
            if (resp) {
                this.getProjectById();
                dialogRef.close();
                this.alertService.openInfoModal(this.translate.instant('Success'), 'Project wifi settings has been updated.');
            }
        }, (err) => {
            dialogRef.close();
            this.alertService.openErrorModal('Error', err.error.error.message);
        });
    }

    projectWifiChangeSTSettings(project, data, dialogRef) {
        this.projectsService.projectWifiChangeSTSettings({
            data: {
                projectUuid: project.uuid,
                ssid: data.ssid,
                password: data.password
            }
        }).subscribe(resp => {
            if (resp) {
                this.getProjectById();
                dialogRef.close();
                this.alertService.openInfoModal(this.translate.instant('Success'), 'Project system wifi settings has been updated.');
            }
        }, (err) => {
            dialogRef.close();
            this.alertService.openErrorModal('Error', err.error.error.message);
        });
    }

    navigateToMapLocation(location) {
        const link = `https://www.google.com/maps/search/${location.latitude},${location.longitude}`;
        window.open(link, '_blank');
    }

    getChangeProjectNamePermission() {
        return this.permissionsService.getPermission(PERMISSIONS.CHANGE_NAME, this.projectId);
    }


    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
