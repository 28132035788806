<ul class="template-sensor-filter text-capitalize">
  <li *ngFor="let sensor of sensorTemplateFilterList; let i = index" [ngClass]="sensor.selected ? 'active' : ''"
      class="sensor-item" (click)="checkSensorPage(sensor)">
    <ng-container>
      <label [for]="'sensor' + '-' + i" #sensorfilter class="sensor-filter">
        <input type="radio" [id]="'sensor' + '-' + i" name="sensorfilter" #radio
               [checked]="sensor.selected" class="hidden">
        <mat-icon [svgIcon]="sensor.icon" class="icon"></mat-icon>
        <span>{{(sensor.name).toLowerCase() | translate }}</span>
      </label>
    </ng-container>

  </li>
</ul>
