<div class="alert">
  <div class="alert-card">
    <div class="pop-up-card-header center alert-header">
      <span class="alert-title">{{data.title}}</span>
    </div>
    
    <div>
      <div class="pop-up-card-logo-block alert-card-logo-block">
          <span class="alert-icon material-icons">
            {{getAlertIcon()}}
          </span>
      </div>
      <div class="alert-msg">
        {{data.message}}
      </div>
    </div>
  
  </div>
</div>
