import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AlertType} from '../alert/alert.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from "../../../core/services/projects/projects.service";

@Component({
    selector: 'app-edit-project-name-dialog',
    templateUrl: './edit-project-name-dialog.component.html',
    styleUrls: ['./edit-project-name-dialog.component.scss']
})
export class EditProjectNameDialogComponent implements OnInit {
    editProjectNameForm: FormGroup;
    editProjectAliasForm: FormGroup;
    projectName: string;
    uuid: string;
    editProjectAlias = true;
    isChangeProjectNameEnabled = false;
    isSubmitted;
    onSave = new EventEmitter();

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<EditProjectNameDialogComponent>,
        private fb: FormBuilder,
        private projectsService: ProjectsService
    ) {
        this.projectName = data.projectName;
        this.isSubmitted = data.isSubmitted;
        this.uuid = data.uuid;
    }

    ngOnInit() {
        this.editProjectNameForm = this.fb.group({
            name: new FormControl(this.projectName, [Validators.required]),
        });

        this.editProjectAliasForm = this.fb.group({
            alias: new FormControl("", [Validators.required]),
        });

        this.projectsService.getProjectsAlias({modelUuid: this.uuid, aliasType: 'project'}).subscribe((res) => {
            this.editProjectAliasForm.setValue({ alias: res.data[0]?.alias || this.projectName });
        })
    }

    save() {
        this.isSubmitted = true;
        if (this.editProjectAlias) {
            this.onSave.emit({...this.editProjectAliasForm.value, isAlias: true});
        } else {
            this.onSave.emit(this.editProjectNameForm.value);
        }
    }

    close() {
        this.dialogRef.close();
    }

    changeAlias(value) {
        this.editProjectAlias = value;
    }
}

