import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ResizeService} from '../../../core/services/resize/resize.service';
import {EventEmitter} from  '@angular/core' ;

interface MousePosition {
  'x': number,
  'y': number
}

const PADDING = 30;

interface Point {
  x: number,
  y: number;
}

interface Range {
  x: number,
  yMin: number,
  yMax: number
}

enum GraphType {
  line,
  range
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})

export class GraphComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {


  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  public ctx: CanvasRenderingContext2D;
  @Input() points: Point[] = [];
  @Input() rangePoints: Range[] = [];
  @Input() graphType: GraphType = GraphType.range;
  @Input() sensorRange = {x: 0, y: 0, width: 24, height: 100};
  @Input() gridWidth = 24;
  @Input() zoom = false;
  @Input() changed = false;
  @Input() disableInteractions = false;
  @Output() changedChange = new EventEmitter<boolean>();

  activePoint: Point = null;
  activeRangePoint: Range = null;

  // function (value: number). Converts value from graph space to unit space
  valueToUnitFunc = null;

  // Transform matrix
  screenSize = {x: 40, y: 15, width: 900, height: 500};
  viewMatrix = new DOMMatrix()
      .translate(this.screenSize.x, this.screenSize.height - this.screenSize.y, 0).scale(1, -1, 1) // inverse
      .scale((this.screenSize.width - this.screenSize.x) / this.sensorRange.width,
             (this.screenSize.height - this.screenSize.y) / this.sensorRange.height, 1) // converting point to screen coordinates
      .translate(-this.sensorRange.x, -this.sensorRange.y, 0);
  modelMatrix = new DOMMatrix(); // point zoom, scale, translate
  radius = 15;
  @Input() fontSize = '12px';

  px = 0;
  py = 0;
  stopAnimation = false;
  @Input() graphContainerWidth: number;

  constructor(private resizeService: ResizeService, private changeDetector: ChangeDetectorRef) {
  }

  setPoints(points: Point[]) {
    this.points = points;
    this.graphType = GraphType.line;
    this.updateState(false);
  }

  setRangePoints(points: Range[]) {
    this.rangePoints = points;
    this.graphType = GraphType.range;
    this.updateState(false);
  }

  setSensorRange(range) {
    this.sensorRange = range;
    this.recalculateViewMatrix();
  }

  setValueToUnitFunc(func) {
    this.valueToUnitFunc = func;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.mouseEvents();
    this.draw();
    setTimeout(() => {
      // this.graphWrapper = document.querySelector('.custom-template-graph');
      // this.graphWrapperWidth = this.graphWrapper.clientWidth;
      this.screenSize.width = this.graphContainerWidth - PADDING * 2;
      this.createHiDPICanvas(this.graphContainerWidth - PADDING * 2, 500);
      this.recalculateViewMatrix();

    }, 500);
    this.resizeService.innerWidth$.subscribe((res) => {
      if (res < 1224) {
        this.fontSize ='7px'
      }
      setTimeout(() => {
        this.screenSize.width = this.graphContainerWidth - PADDING * 2;
        this.createHiDPICanvas(this.graphContainerWidth - PADDING * 2, 500);
        this.recalculateViewMatrix();
      }, 300)
      // this.canvas.nativeElement.style.width = this.graphContainerWidth + 'px';

    })
  }

  ngAfterContentChecked() : void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.canvas.nativeElement.onmouseup = null;
    this.canvas.nativeElement.onmousedown = null;
    this.canvas.nativeElement.onwheel = null;
    this.stopAnimation = true;
  }

  private yAxisLabel(y: number): string {
    return this.valueToUnitFunc ? this.valueToUnitFunc(y).toFixed(1) : y.toFixed(1);
  }

  updateState(isChanged: boolean) {
    this.changed = isChanged;
    this.changedChange.emit(isChanged);
  }

  get scale() {
    return this.modelMatrix.a;
  }

  get matrix() {
    return this.viewMatrix.multiply(this.modelMatrix)
  }

  // Convers model point to View spavce
  toViewSpace(point) {
    return new DOMPoint(point.x, point.y).matrixTransform(this.matrix);
  }

  // Convert screen coordinate to Model space
  toModelSpace(point) {
    return new DOMPoint(point.x, point.y).matrixTransform(this.matrix.inverse());
  }

  linePointToModelSpace(point) {
    const linePoint = this.toViewSpace(new DOMPoint(point.x, point.y));
    return {x: linePoint.x, y: linePoint.y}
  }

  isPointClick(point, center, radius) {
    return Math.abs(point.x - center.x) < radius && Math.abs(point.y - center.y) < radius;
  }

  // Returns true if mouse is in chart canvas range
  isMousePositionInCanvasRange(e): Boolean {
    const point = this.getMousePos(this.canvas.nativeElement, e);
    // Y axis is at the bottom so y canvas range is in [0, height - yOffset]
    return point.x > this.screenSize.x && point.x < this.screenSize.x + this.screenSize.width &&
           point.y > 0 && point.y < this.screenSize.height - this.screenSize.y;
  }

  recalculateViewMatrix() {
    this.viewMatrix = new DOMMatrix()
        .translate(this.screenSize.x, this.screenSize.height - this.screenSize.y, 0).scale(1, -1, 1) // inverse
        .scale((this.screenSize.width - this.screenSize.x) / this.sensorRange.width,
               (this.screenSize.height - this.screenSize.y) / this.sensorRange.height, 1) // converting point to screen coordinates
        .translate(-this.sensorRange.x, - this.sensorRange.y, 0);
  }

  resetActivePoints(): void {
    if (this.graphType === GraphType.line) {
      for (const point of this.points) {
        point['fillStyle'] = null;
        point['pointTextColor'] = null;
      }
    } else {
      for (const point of this.rangePoints) {
        point['topFillStyle'] = null;
        point['bottomFillStyle'] = null;
        point['pointTextColor'] = null;
      }
    }

  }

  definePixelRatio() {
    const dpr = window.devicePixelRatio || 1;
    const bsr = this.ctx['webkitBackingStorePixelRatio'] ||
        this.ctx['mozBackingStorePixelRatio'] ||
        this.ctx['msBackingStorePixelRatio'] ||
        this.ctx['oBackingStorePixelRatio'] ||
        this.ctx['backingStorePixelRatio'] || 1;
    return dpr / bsr;
  }

  createHiDPICanvas(w, h, ratio?) {
    if (!ratio) {
      ratio = this.definePixelRatio();
    }
    this.canvas.nativeElement.width = w * ratio;
    this.canvas.nativeElement.height = h * ratio;
    this.canvas.nativeElement.style.width = w + 'px';
    this.canvas.nativeElement.style.height = h + 'px';
    this.canvas.nativeElement.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
    return this.canvas.nativeElement;
  }


  movePoint(e, point): void {
    this.updateState(true);
    const position = this.toModelSpace(this.getMousePos(this.canvas.nativeElement, e));
    point.x = +position.x.toFixed(1);
    point.y = +position.y.toFixed(1);
    this.canvas.nativeElement.onmouseup = () => {
      this.canvas.nativeElement.onmousemove = null;
    }
    return point;
  }

  moveGrid(e) {
    const p1 = this.toModelSpace({x: this.px, y: this.py});
    const p2 = this.toModelSpace({x: e.x, y: e.y});
    const translateMatrix = new DOMMatrix().translate(p2.x - p1.x, p2.y - p1.y);

    this.modelMatrix = translateMatrix.multiply(this.modelMatrix);
    this.px = e.x;
    this.py = e.y;
  }

  normalizePoint(point: Point) {
    point.x = Math.min(this.sensorRange.x + this.sensorRange.width, Math.max(point.x, this.sensorRange.x));
    point.y = Math.min(this.sensorRange.y + this.sensorRange.height, Math.max(point.y, this.sensorRange.y));
  }

  normalizeRangePoint(point: Range) {
    point.x = Math.min(this.sensorRange.x + this.sensorRange.width, Math.max(point.x, this.sensorRange.x));
    point.yMin = Math.min(this.sensorRange.y + this.sensorRange.height, Math.max(point.yMin, this.sensorRange.y));
    point.yMax = Math.min(this.sensorRange.y + this.sensorRange.height, Math.max(point.yMax, this.sensorRange.y));
  }

  handlePointClick(e) {
    const {offsetY, offsetX} = e;
    let isPointDown = false;
    const m = this.matrix;

    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];
      const position = new DOMPoint(point.x, point.y).matrixTransform(m);
      if (this.isPointClick(position, new DOMPoint(offsetX, offsetY), this.radius)) {
        this.resetActivePoints();
        this.points[i]['fillStyle'] = '#fa9b70';
        this.points[i]['pointTextColor'] = '#fff';
        this.activePoint = this.points[i];
        isPointDown = true;
        this.canvas.nativeElement.onmousemove = (ev) => {
          this.movePoint(ev, this.points[i]);
         this.normalizePoint(point);
        }
        break;
      } else if (this.activePoint === this.points[i] && this.isPointClick({
        x: position.x + this.radius,
        y: position.y - this.radius
      }, new DOMPoint(offsetX, offsetY), this.radius)) {
        isPointDown = true;
        this.deleteActivePoint();
        break;

      }
    }
    return isPointDown;
  }

  handleRangeClick(e) {
    const {offsetY, offsetX} = e;
    let isPointDown = false;
    const m = this.matrix;

    for (let i = 0; i < this.rangePoints.length; i++) {
      const point = this.rangePoints[i];
      const topPoint = new DOMPoint(point.x, point.yMax);
      const bottomPoint = new DOMPoint(point.x, point.yMin);
      const topPosition = topPoint.matrixTransform(m);
      const bottomPosition = bottomPoint.matrixTransform(m);
      if (this.isPointClick(topPosition, new DOMPoint(offsetX, offsetY), this.radius)) {
        this.resetActivePoints();
        this.rangePoints[i]['topFillStyle'] = '#fa9b70';
        this.rangePoints[i]['pointTextColor'] = '#fff';
        this.activeRangePoint = this.rangePoints[i];
        isPointDown = true;
        this.canvas.nativeElement.onmousemove = (ev) => {
          this.movePoint(ev, topPoint);
          point.x = topPoint.x;
          point.yMax = Math.max(topPoint.y, point.yMin);
          this.normalizeRangePoint(point);
        }
        break;
      } else if (this.isPointClick(bottomPosition, new DOMPoint(offsetX, offsetY), this.radius)) {
        this.resetActivePoints();

        this.rangePoints[i]['bottomFillStyle'] = '#fa9b70';
        this.rangePoints[i]['pointTextColor'] = '#fff';
        this.activeRangePoint = this.rangePoints[i];
        isPointDown = true;
        this.canvas.nativeElement.onmousemove = (ev) => {
          this.movePoint(ev, bottomPoint);
          point.x = bottomPoint.x;
          point.yMin = Math.min(bottomPoint.y, point.yMax);
          this.normalizeRangePoint(point);
        };
        break;
      } else if (this.activeRangePoint === this.rangePoints[i] && this.activeRangePoint['topFillStyle']  && this.isPointClick({
        x: topPosition.x + this.radius,
        y: topPosition.y - this.radius
      }, new DOMPoint(offsetX, offsetY), this.radius)) {
        isPointDown = true;
        this.deleteActiveRangePoint();
        break;
      } else if (this.activeRangePoint === this.rangePoints[i] && this.activeRangePoint['bottomFillStyle'] && this.isPointClick({
        x: bottomPosition.x + this.radius,
        y: bottomPosition.y - this.radius
      }, new DOMPoint(offsetX, offsetY), this.radius)) {
        isPointDown = true;
        this.deleteActiveRangePoint();
        break;
      }
    }
    return isPointDown;
  }

  //  Mouse events
  mouseEvents() {
    // prevent default right click behavior
    this.canvas.nativeElement.oncontextmenu = (e) => {
      return false;
    };
    if (!this.disableInteractions) {
      this.canvas.nativeElement.onmousedown = (e) => {
        // return if right click
        if(e.button === 2) {
          e.preventDefault();
          return
        }

        const isPointDown = this.graphType === GraphType.line ? this.handlePointClick(e) : this.handleRangeClick(e);

        if (!isPointDown) {
          this.px = e.x;
          this.py = e.y;
          this.canvas.nativeElement.onmousemove = (ev) => {
            if (!this.zoom) return;
            this.moveGrid(ev);
            this.canvas.nativeElement.onmouseup = () => {
              this.canvas.nativeElement.onmousemove = null;
            }
          }
          this.canvas.nativeElement.onmouseup = (evt) => {
            const delta = 5
            // check if mouse down event coordinates equal mouse up event coordinates
            if (Math.abs(e.x - evt.x) > delta || Math.abs(e.y - evt.y) > delta) {
              return
            }

            // Convert screen position to canvas position. 
            // Don't add point if mouse is out of chart canvas
            if (!this.isMousePositionInCanvasRange(e)) {
              return;
            }

            if (this.graphType === GraphType.line) {
              this.addPoint(evt);
            } else {
              this.addRangePoint(evt);
            }
          }
        }
      };

      this.canvas.nativeElement.onmouseup = (e) => {
        this.canvas.nativeElement.onmousemove = null;
      }
    }



    /// ZOOM, onwheel
    if (this.zoom) {
      this.canvas.nativeElement.onwheel = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const scale = 1.0 - Math.max(Math.min(e.deltaY / 100, 1.0), -1.0); /// Zoom [0, n], 1 = no zoom, ??????????????
        const position = this.toModelSpace(this.getMousePos(this.canvas.nativeElement, e));

        // Zoom center
        let scaledMatrix = new DOMMatrix().translate(position.x, position.y);
        scaledMatrix = scaledMatrix.scale(scale, scale);
        scaledMatrix = scaledMatrix.translate(-position.x, -position.y);
        this.modelMatrix = scaledMatrix.multiply(this.modelMatrix);
      }
    }

  }

  deleteActivePoint() {
    this.updateState(true);
    this.points = this.points.filter(e => {
      return e !== this.activePoint;
    })
  }

  deleteActiveRangePoint() {
    this.updateState(true);
    this.rangePoints = this.rangePoints.filter(e => {
      return e !== this.activeRangePoint;
    })
  }

  addPoint(e) {
    this.updateState(true);
    this.canvas.nativeElement.onmouseup = null;
    this.canvas.nativeElement.onmousemove = null;
    this.px = e.x;
    this.py = e.y;
    const newPoint = this.toModelSpace(this.getMousePos(this.canvas.nativeElement, e));
    newPoint.x = +newPoint.x.toFixed(1);
    newPoint.y = +newPoint.y.toFixed(1);
    newPoint['fillStyle'] = null;
    this.points.push(newPoint);
  }

  addRangePoint(e) {
    this.updateState(true);
    this.canvas.nativeElement.onmouseup = null;
    this.canvas.nativeElement.onmousemove = null;
    this.px = e.x;
    this.py = e.y;
    const point = this.toModelSpace(this.getMousePos(this.canvas.nativeElement, e));
    const yMin = Math.max(point.y - this.sensorRange.height * 0.2, this.sensorRange.y);
    const newPoint: Range = {x: point.x, yMin: yMin, yMax: point.y};
    newPoint.x = +newPoint.x.toFixed(1);
    newPoint.yMin = +newPoint.yMin.toFixed(1);
    newPoint.yMax = +newPoint.yMax.toFixed(1);
    newPoint['fillStyle'] = null;
    this.rangePoints.push(newPoint);
  }

  getMousePos(canvas, evt): MousePosition {
    const rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }

  draw() {
    if (!this.stopAnimation) {
      window.requestAnimationFrame(this.draw.bind(this));
      this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      this.drawGrid();
      if (this.graphType === GraphType.line) {
        this.drawLineToPoint();
        this.drawPoints();
      } else {
        this.drawFilledRange();
        this.drawRangePoints();
      }
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle = '#000000';
      this.ctx.strokeRect(this.screenSize.x, 0, this.screenSize.width - this.screenSize.x, this.screenSize.height - this.screenSize.y);
    }
  }

  getTime(point): string {
    if (point === '0.0') return '';
    let hours = (+point.split('.')[0]).toString();
    if (hours === '24') hours = '00';
    if (hours.length === 1) hours = '0' + hours;
    return hours + ':' + '00';
  }

  drawGrid(): void {
    this.ctx.save();
    // Step depends on zoom
    const stepX = (this.sensorRange.width / this.gridWidth) / this.scaledStep(this.scale);
    const stepY = (this.sensorRange.height / this.gridWidth) / this.scaledStep(this.scale);

    const startPoint = this.toModelSpace({x: 0, y: 0});
    const endPoint = this.toModelSpace({x: this.screenSize.width, y: this.screenSize.height});
    this.ctx.beginPath();
    this.ctx.lineWidth = 1;
    const trMatrix = this.matrix;
    // Vertical
    const xRange = this.makeRange(Math.floor(startPoint.x / stepX) * stepX, Math.ceil(endPoint.x / stepX) * stepX, stepX);
    for (const x of xRange) {
      this.ctx.setLineDash([10, 12]);
      const point = new DOMPoint(x, 0).matrixTransform(trMatrix);
      if (point.x < this.screenSize.x) {
        continue;
      }
      this.ctx.strokeStyle = '#dadada';
      this.ctx.moveTo(Math.round(point.x), 0);
      this.ctx.lineTo(Math.round(point.x), this.screenSize.height - this.screenSize.y);
      this.ctx.fillStyle = '#000';
      this.ctx.font = `300 ${this.fontSize} Arial`;

      this.ctx.fillText(this.getTime(x.toFixed(1)), Math.round(point.x - 15), this.screenSize.height - 5);
      this.ctx.stroke()
    }

    // Horizontal
    const yRange = this.makeRange(Math.floor(endPoint.y / stepY) * stepY, Math.ceil(startPoint.y / stepY) * stepY, stepY);
    for (const y of yRange) {
      const point = new DOMPoint(0, y).matrixTransform(trMatrix);
      if (point.y < this.screenSize.y || point.y < 0) {
        continue;
      }
      this.ctx.fillText(this.yAxisLabel(y), 20, Math.round(point.y + 2));
      this.ctx.stroke();
    }
    this.ctx.restore();
  }

  drawPoint(point) {
    this.ctx.beginPath();
    const transformedPoint = new DOMPoint(point.x, point.y).matrixTransform(this.matrix);
    this.ctx.beginPath();
    this.ctx.setLineDash([]);
    this.ctx.ellipse(transformedPoint.x, transformedPoint.y, this.radius, this.radius, 0, 0, 2 * Math.PI);
    this.ctx.strokeStyle = '#000';

    if (point['fillStyle']) {
      this.ctx.fillStyle = '#fa9b70';
      this.ctx.fill();
    } else {
      this.ctx.fillStyle = '#fff';
      this.ctx.fill();
      this.ctx.stroke();
    }
    this.ctx.fillStyle = point['pointTextColor'];
    this.ctx.fillStyle = point['fillStyle'] === undefined || point['fillStyle'] === null ? '#fa9b70' : '#fff';
    this.ctx.fillText(`${this.yAxisLabel(point.y)}`, +Math.round(transformedPoint.x), +Math.round(transformedPoint.y + 1));
    this.ctx.closePath();
    if (point['fillStyle']) {
      const x = transformedPoint.x + this.radius + 2;
      const y = transformedPoint.y - this.radius - 2;
      this.ctx.beginPath();
      this.ctx.fillStyle = '#000';
      this.ctx.ellipse(x, y, 6, 6, 0, 0, 2 * Math.PI);
      this.ctx.stroke();
      this.ctx.closePath();
      this.ctx.beginPath();
      this.ctx.moveTo(x - 3, y - 3);
      this.ctx.lineTo(x + 3, y + 3);
      this.ctx.moveTo(x + 3, y - 3);
      this.ctx.lineTo(x - 3, y + 3);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  drawLineToPoint() {
    if (this.points.length === 0) return;
    const sortedPoints = this.points.slice(0).sort((a: Point, b: Point) => {
      return a.x - b.x
    }).map(a => this.linePointToModelSpace(a));

    this.ctx.beginPath();
    this.ctx.lineWidth = 1;
    this.ctx.save();
    let prevPoint = sortedPoints[0];
    this.ctx.moveTo(this.screenSize.x, prevPoint.y);
    for (const point of sortedPoints) {
      const p = point;
      this.ctx.lineTo(p.x, prevPoint.y);
      this.ctx.lineTo(p.x, p.y);
      prevPoint = p;
    }
    this.ctx.lineTo(this.screenSize.width, prevPoint.y);

    this.ctx.strokeStyle = '#2d4a30';
    this.ctx.stroke();
    this.ctx.restore();
  }

  drawPoints() {
    this.ctx.beginPath();
    this.ctx.textBaseline = 'middle';
    this.ctx.textAlign = 'center';
    this.ctx.save();
    this.ctx.fillStyle = '#000';
    this.ctx.rect(this.screenSize.x, 0, this.screenSize.width - this.screenSize.x, this.screenSize.height - this.screenSize.y);
    this.ctx.clip();
    this.ctx.closePath();
    for (const point of this.points) {
      this.drawPoint(point)
    }

    this.ctx.restore();
    this.ctx.font = '300 12px Arial';

  }

  drawRangePoints() {
    this.ctx.beginPath();
    this.ctx.textBaseline = 'middle';
    this.ctx.textAlign = 'center';
    this.ctx.save();
    this.ctx.fillStyle = '#000';
    this.ctx.rect(this.screenSize.x, 0, this.screenSize.width - this.screenSize.x, this.screenSize.height - this.screenSize.y);
    this.ctx.clip();
    this.ctx.closePath();
    for (const point of this.rangePoints) {
      this.drawPoint({x: point.x, y: point.yMin, fillStyle: point['bottomFillStyle']});
      this.drawPoint({x: point.x, y: point.yMax, fillStyle: point['topFillStyle']});
    }

    this.ctx.restore();
    this.ctx.font = '300 12px Arial';
  }

  drawFilledRange() {
    if (this.rangePoints.length === 0) return;
    const sortedPoints = this.rangePoints.slice(0).sort((a: Range, b: Range) => {
      return a.x - b.x
    }).map(a => this.rangePointToModelSpace(a));

    this.ctx.beginPath();
    this.ctx.lineWidth = 1;
    this.ctx.save();
    let prevPoint = sortedPoints[0];
    this.ctx.moveTo(this.screenSize.x, prevPoint.yMax);
    this.ctx.lineTo(prevPoint.x, prevPoint.yMax);
    for (let point of sortedPoints) {
      const p = point;
      this.ctx.lineTo(p.x, prevPoint.yMax);
      this.ctx.lineTo(p.x, p.yMax);
      prevPoint = p;
    }
    this.ctx.lineTo(this.screenSize.width, prevPoint.yMax);
    this.ctx.lineTo(this.screenSize.width, prevPoint.yMin);

    for (let point of sortedPoints.reverse()) {
      const p = point;
      this.ctx.lineTo(prevPoint.x, p.yMin);
      this.ctx.lineTo(p.x, p.yMin);
      prevPoint = p;
    }
    this.ctx.lineTo(this.screenSize.x, prevPoint.yMin);
    this.ctx.strokeStyle = '#2d4a30';
    this.ctx.fillStyle = '#bde1e2';
    this.ctx.stroke();
    this.ctx.fill();
    this.ctx.restore();
  }

  rangePointToModelSpace(point) {
    const topPoint = this.toViewSpace(new DOMPoint(point.x, point.yMin));
    const bottomPoint = this.toViewSpace(new DOMPoint(point.x, point.yMax));
    return {x: topPoint.x, yMin: topPoint.y, yMax: bottomPoint.y}
  }

  makeRange(start, stop, step) {
    const a = [start];
    let b = start;
    while (b < stop) {
      a.push(b += step || 1);
    }
    return a;
  }

  scaledStep(scale) {
    return scale > 1 ? Math.pow(2, Math.floor(Math.log2(scale))) : 1.0 / Math.pow(2, Math.ceil(Math.log2(1.0 / scale)))
  }

}
