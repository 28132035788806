import { MasterDevice } from "./masterdevice.model";
import { Sensor } from "./sensor";

export interface Project {
  createdAt: string,
  deletedAt: string,
  description: string,
  id: number,
  name: string,
  updatedAt: string,
  timeZoneId: string,
  devices?: [MasterDevice]
}

/// Returns number of equal sensors in one room
export function eqaulSensorsInRoom(type: Sensor, roomId: number, project: Project): number {
  let count = 0;
  project.devices.forEach(mDevice => {
    mDevice.devices.forEach(device => {
      if (device.roomId !== roomId) { return }
      device.sensors.forEach( sensor => {
        if (sensor.type === type) {
          count += 1;
        }
      })
    })
  })
  return count;
}
