import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
