<div class="confirm">
    <div class="confirm-card">
      <div>
        <div class="confirm-msg">
          <p>{{'deprecated_app_version_message' | translate}}</p>
          <p><a href="{{url}}">{{url.replace("https://", "")}}</a></p>
        </div>
      </div>
      <mat-dialog-actions align="center">
        <button mat-flat-button color="primary" class="confirm-btns" (click)="onOpenNewVersion()">
            {{'open' | translate}}
        </button>
      </mat-dialog-actions>
    
    </div>
  </div>
  