import {AfterViewInit, Component, ElementRef, ViewEncapsulation, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EventEmitter} from  '@angular/core' ;
import {FormGroup, FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-data-range-calendar',
  templateUrl: './data-range-calendar.component.html',
  styleUrls: ['./data-range-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DataRangeCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() changedDataRange = new EventEmitter();
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();
  @Input('startDate') startDate;
  @Input('endDate') endDate;

  maxDate = new Date();

  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  // dateRangeChange(dateRangeStart, dateRangeEnd) {
  //   const startDate = dateRangeStart.value ? Date.parse(dateRangeStart.value)/1000 : null;
  //   const endDate = dateRangeEnd.value ? Date.parse(dateRangeEnd.value)/1000 : null;
  //   this.changedDataRange.emit({start: startDate, end: endDate})
  // }

  ngOnInit(): void {
    this.dateRange.patchValue({start: new Date(this.startDate * 1000), end: new Date(this.endDate * 1000)})
    this.dateRange.valueChanges.subscribe((res) => {
      const startDate = res.start;
      let endDate = res.end;
      if (startDate > endDate) {
        this.dateRange.controls['end'].setErrors({'matEndDateInvalid': true});
      }
      this.changedDataRange.emit({start: Date.parse(startDate) / 1000, end: Date.parse(endDate) / 1000})
    })
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }


}
