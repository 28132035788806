import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTreeModule} from '@angular/material/tree';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import { ChartsModule } from 'ng2-charts';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';

import {AlertComponent} from './components/alert/alert.component';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {HeaderComponent} from './components/header/header.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {SelectProjectMenuComponent} from './components/header/components/select-project-menu/select-project-menu.component';
import {AlertService} from '../core/services';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {WeatherSectionComponent} from './components/header/components/weather-section/weather-section.component';
import {SystemStatusSectionComponent} from './components/header/components/system-status-section/system-status-section.component';
import {LayoutComponent} from './components/layout/layout.component';
import {TopFilterComponent} from './components/filters/top-filter/top-filter.component';
import {DashboardRoomFilterComponent} from './components/filters/dashboard-room-filter/dashboard-room-filter.component';
import {TemplateSensorFilterComponent} from './components/filters/template-sensor-filter/template-sensor-filter.component';
import {TemplatesListComponent} from './components/templates-list/templates-list.component';
import {DashboardRoomsComponent} from './components/dashboard-rooms/dashboard-rooms.component';
import {FilterBySensor} from './pipes/sensor.pipe';
import {DashboardRoomSchedulerComponent} from '../modules/dashboard/components/dashboard-room-scheduler/dashboard-room-scheduler.component';
import {EditProjectComponent} from '../modules/projects/components/edit-project/edit-project.component';
import {GraphComponent} from './components/graph/graph.component';
import {HistoryChartComponent} from './components/history-chart/history-chart.component';
import {DataRangeCalendarComponent} from './components/data-range-calendar/data-range-calendar.component';
import {SchedulerActionsComponent} from './components/scheduler-actions/scheduler-actions.component';
import {EditProjectNameDialogComponent} from './components/edit-project-name-dialog/edit-project-name-dialog.component';
import {EditProjectWifiDialogComponent} from './components/edit-project-wifi-dialog/edit-project-wifi-dialog.component';
import {CreateDemoProjectDialogComponent} from './components/create-demo-project-dialog/create-demo-project-dialog.component';
import { ShareProjectComponent } from './components/share-project/share-project.component';
import { LinkCreatedDialogComponent } from './components/link-created-dialog/link-created-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import { EditProjectAdjustmentsComponent } from './components/edit-project-adjustments/edit-project-adjustments.component';
import { ModalWindowForDownloadDataComponent } from './components/modal-window-for-download-data/modal-window-for-download-data.component';
import { ModalWindowSuccessComponent } from './components/modal-windows-success/modal-window-success.component';
import { DeprecatedAppWarningComponent } from './components/deprecated-app-warning/deprecated-app-warning.component';


/**
 * SharedModule contains code that will be used across the app and Feature Modules. This includes:
 * - common stateless components ('dumb' components)
 * - commonly used pipes and directives
 * - export the commonly used Angular modules (eg CommonModule or the FormsModule) so they can be easily used across the app
 * - export UI Modules (eg Angular Material) or components that are used a lot in the application
 * Note: this might introduce unnecessary loading speed if you don't use those modules/components a lot;
 *       if that's the case, you might want to import these Angular modules only in the Feature Modules that use them.
 *
 * Important note:
 * DO NOT import the SharedModule into the main AppModule or CoreModule, only in specific Feature Modules as needed
 */

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatCardModule,
    MatTabsModule,
    MatTreeModule,
    MatSidenavModule,
    MatListModule,
    MatRadioModule,
    MatSliderModule,
    NgbModule,
    TranslateModule,
    ChartsModule,
    MatExpansionModule
  ],
  declarations: [
    AlertComponent,
    ConfirmComponent,
    EditProjectNameDialogComponent,
    HeaderComponent,
    NavbarComponent,
    SelectProjectMenuComponent,
    WeatherSectionComponent,
    SystemStatusSectionComponent,
    LayoutComponent,
    TopFilterComponent,
    DashboardRoomFilterComponent,
    TemplateSensorFilterComponent,
    TemplatesListComponent,
    DashboardRoomsComponent,
    DashboardRoomSchedulerComponent,
    EditProjectComponent,
    EditProjectWifiDialogComponent,
    CreateDemoProjectDialogComponent,
    GraphComponent,
    FilterBySensor,
    HistoryChartComponent,
    DataRangeCalendarComponent,
    SchedulerActionsComponent,
    ShareProjectComponent,
    LinkCreatedDialogComponent,
    EditProjectAdjustmentsComponent,
    ModalWindowForDownloadDataComponent,
    ModalWindowSuccessComponent,
    DeprecatedAppWarningComponent, // pipe
  ],
  exports: [
    /* core modules */
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatCardModule,
    MatTabsModule,
    MatTreeModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatRadioModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AlertComponent,
    ConfirmComponent,
    EditProjectNameDialogComponent,
    HeaderComponent,
    SelectProjectMenuComponent,
    WeatherSectionComponent,
    SystemStatusSectionComponent,
    NavbarComponent,
    TopFilterComponent,
    DashboardRoomFilterComponent,
    TemplateSensorFilterComponent,
    TemplatesListComponent,
    DashboardRoomsComponent,
    DashboardRoomSchedulerComponent,
    EditProjectComponent,
    EditProjectWifiDialogComponent,
    CreateDemoProjectDialogComponent,
    GraphComponent,
    HistoryChartComponent,
    DataRangeCalendarComponent,
    TranslateModule,
    FilterBySensor, // pipe,
    MatExpansionModule
  ],
  providers: [AlertService],
  entryComponents: [
    AlertComponent,
    ConfirmComponent,
    EditProjectNameDialogComponent,
    EditProjectWifiDialogComponent,
    CreateDemoProjectDialogComponent,
    SchedulerActionsComponent
  ]
})
export class SharedModule {
}
