import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { map, switchMap } from "rxjs/operators";
import { DeprecatedAppWarningComponent } from "src/app/shared/components/deprecated-app-warning/deprecated-app-warning.component";
import { UsersService } from "../users/users.service";


@Injectable({
    providedIn: 'root'
})
export class AppService {
    
    private minVersion = 1;

    constructor(
        private usersService: UsersService,
        private dialog: MatDialog
    ) {
        
    } 

    // Verifies if app version for this user is depricated or not. 
    // If yes, display warning alert and redirect to a new version
    validateVersion() {
        this.usersService.getSettings().pipe(
            map(res => res.data)
        ).subscribe(settings => {
            if (settings?.webAppVersion > this.minVersion) {
                this.dialog.open(DeprecatedAppWarningComponent, { disableClose: true });
              }
        });
    }
}