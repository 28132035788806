<div class="edit-project-dialog">
  <div class="edit-project-dialog-card" *ngIf="!isSubmitted">
    <div class="pop-up-card-header center edit-project-dialog-header">
      <span class="edit-project-dialog-title">{{'create_demo_project' | translate}}</span>
    </div>
    <div class="edit-project-dialog-content">
      <mat-radio-group [labelPosition]="'before'"
        aria-labelledby="template-radio-group-label"
        class="radio-group-list"
        [(ngModel)]="selectedDemoTemplate">
          <mat-radio-button class="template-radio-button" *ngFor="let template of templatesList" [value]="template">
            {{template.name}}
            <span class="template-room text-capitalize">{{template.description}}</span>
          </mat-radio-button>
      </mat-radio-group>
    </div>
    
    <mat-dialog-actions align="center">
      <button mat-stroked-button color="primary" class="edit-project-dialog-btns" (click)="close()">
        {{'cancel' | translate}}
      </button>
      <button mat-flat-button color="primary" class="edit-project-dialog-btns" (click)="save()">
        {{'done' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
  
  <div class="edit-project-dialog-card" *ngIf="isSubmitted">
    <div class="pop-up-card-header center edit-project-dialog-header">
      <span class="edit-project-dialog-title">{{'please_wait' | translate}}</span>
    </div>
    <div class="edit-project-dialog-content edit-project-dialog-loader loader-inline">
      <img src="../../../../assets/spinner.gif" class="rotate-spinner">
    </div>
  </div>
</div>
