<ul class="edit-project col-12">
  <div>
    <div class="edit-project-breadcrumbs">
      <span class="pointer" routerLink="/projects">Projects </span>
      <span> &gt; </span>
      <span class="main-color">{{project?.name}}</span>
    </div>
  </div>
  <li class="edit-project-item" *ngIf="project">
    <div class="edit-project-item-element">
      <h4 class="edit-project-item-element-title">{{'general' | translate}}</h4>
      <div class="flex-column justify-content-space-between">
        <div class="edit-project-item-title">{{projectAlias || project.name}}</div>
        <div *ngIf="getChangeProjectNamePermission() | async"  class="edit-action" (click)="editProjectName(project)">
          <mat-icon svgIcon="edit"></mat-icon>
        </div>
      </div>
    </div>
    
    <div class="edit-project-item-element">
      <h4 class="edit-project-item-element-title">{{'location' | translate}}</h4>
      <div class="flex-column justify-content-space-between" (click)="navigateToMapLocation(project.location)">
        <div class="edit-project-item-title project-location">{{project.location.latitude}}, {{project.location.longitude}}</div>
      </div>
    </div>
  </li>
  
  <li class="edit-project-item">
    <div class="edit-project-item-element">
      <h4 class="edit-project-item-element-title">{{'wifi_settings' | translate}}</h4>
      <div class="flex-column justify-content-space-between">
        <div class="edit-project-item-title">{{project?.wifi?.ssid}}</div>
        <div class="edit-action" (click)="editProjectWiFiSettings(project, 'st')">
          <mat-icon svgIcon="edit"></mat-icon>
        </div>
      </div>
    </div>
    <div class="edit-project-item-element">
      <h4 class="edit-project-item-element-title">{{'wifi_system_settings' | translate}}</h4>
      <div class="flex-column justify-content-space-between">
        <div class="edit-project-item-title">{{project?.systemWifi?.ssid}}</div>
        <div class="edit-action" (click)="editProjectWiFiSettings(project, 'ap')">
          <mat-icon svgIcon="edit"></mat-icon>
        </div>
      </div>
    </div>
  </li>

  <li class="edit-project-item">
    <div class="edit-project-item-element">
      <h4 class="edit-project-item-element-title">{{'project_adjustments' | translate}}</h4>
      <div class="flex-column justify-content-space-between">
        <div class="edit-project-item-title">{{'rooms' | translate}}</div>
        <div class="edit-action" (click)="editProjectAdjustments()">
          <mat-icon svgIcon="edit"></mat-icon>
        </div>
      </div>
    </div>
  </li>
</ul>