import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {AlertService} from '../../../../core/services';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import { v4 as uuidv4 } from 'uuid';
import {PermissionsService} from '../../../../core/services/permissions/permissions.service';
import {ProjectsService} from '../../../../core/services/projects/projects.service';
import {PERMISSIONS} from '../../../../models/permission.model';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-template-sensor-filter',
  templateUrl: './template-sensor-filter.component.html',
  styleUrls: ['./template-sensor-filter.component.scss']
})
export class TemplateSensorFilterComponent implements OnInit {

  @Input() sensorTemplateFilterList: any;
  @Output() setFilterSensorType = new EventEmitter();
  @ViewChildren('sensorfilter') sensorfilter: QueryList<any>;
  isCusomPage;

  templatePermissions = [];

  constructor(
    private router: Router,
    private alertService: AlertService,
    private templatesService: TemplatesService,
    private permissionsService: PermissionsService,
    private projectsService: ProjectsService,
    private translate: TranslateService
    ) {
    this.router.events.subscribe((url:any) => url);
    this.isCusomPage = this.router.url.split('/').indexOf('custom') > -1
  }

  ngOnInit(): void {
  }

  warningAlert(item){
    const warning = this.alertService.openConfirmModal(this.translate.instant('warning'),
      this.translate.instant('warning_message'),
      this.translate.instant('ok'),
        this.translate.instant('cancel'),
        (answer: boolean) => {
        if (answer) {
          this.templatesService.setTemplateForCustomization({
            id: null,
            name: '',
            sensorType: item.type,
            uuid: uuidv4(),
            values: []
          });
          this.setActive(item);
          return;
        }
    });
  }

  checkSensorPage(item) {
    if (this.isCusomPage) {
      this.warningAlert(item);
    } else {
      this.setActive(item)
    }
  }

  setActive(item) {
    this.sensorTemplateFilterList.map( sensor => {
      return sensor.selected = false;
    });
    item.selected = !item.selected;
    this.setFilterSensorType.emit(item);
  }

}
