import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../core/services';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public show: boolean = false;
  private subscription: Subscription;
  @Input() inlineStyle: boolean;

  constructor(
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: any) => {
      this.show = state.show;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
