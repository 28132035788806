import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {ProjectsService} from '../../../../../core/services/projects/projects.service';
import {takeUntil} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-select-project-menu',
  templateUrl: './select-project-menu.component.html',
  styleUrls: ['./select-project-menu.component.scss'],
})
export class SelectProjectMenuComponent implements OnInit, OnDestroy {

  projects;
  activeProject: any;
  public toggle = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private subscriptionProjectList: Subscription;
  @Output() changeActiveProject = new EventEmitter();

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private projectsService: ProjectsService
  ) {
    this.subscriptionProjectList= this.projectsService.getUpdateProjectList().subscribe(resp => {
      this.projects = resp;
    });
  }

  ngOnInit() {
    this.projectsService.projects$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.projects = res;
    });
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.activeProject = res;
      this.changeActiveProject.emit(this.activeProject);
    })

  }

  setActiveProject(projectId) {
    this.projectsService.setActiveProject(projectId).subscribe();
    this.toggle = false;
  }

  menuToggle(){
    this.toggle = !this.toggle;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subscriptionProjectList.unsubscribe();
  }

}
