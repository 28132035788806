<div class="edit-project-dialog">
  <div class="edit-project-dialog-card" *ngIf="!isSubmitted">
    <div class="pop-up-card-header center edit-project-dialog-header">
      <span class="edit-project-dialog-title">WiFi Settings</span>
    </div>
    <div class="edit-project-dialog-content">
      <form [formGroup]="editProjectWifiForm">
        <div class="field-row">
          <label class="field-type">
            SSID
          </label>
          <input type="text" placeholder="SSID" formControlName="ssid">
          <div *ngIf="editProjectWifiForm.get('ssid').hasError('required')" class="help-block"
               [ngClass]="{'error': editProjectWifiForm.get('ssid').hasError('required')}">
            <span>SSID is required</span>
          </div>
        </div>
        <div class="field-row">
          <label class="field-type">
            Password
          </label>
          <input type="text" placeholder="Password" formControlName="password">
          <div *ngIf="editProjectWifiForm.get('password').hasError('required')" class="help-block"
               [ngClass]="{'error': editProjectWifiForm.get('password').hasError('required')}">
            <span>Password is required</span>
          </div>
        </div>
      </form>
    </div>
    
    <mat-dialog-actions align="center">
      <button mat-stroked-button color="primary" class="edit-project-dialog-btns" (click)="close()">
        {{'cancel' | translate}}
      </button>
      <button mat-flat-button color="primary" class="edit-project-dialog-btns" [disabled]="editProjectWifiForm.invalid" (click)="save()">
        {{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
  
  <div class="edit-project-dialog-card" *ngIf="isSubmitted">
    <div class="pop-up-card-header center edit-project-dialog-header">
      <span class="edit-project-dialog-title">{{'please_wait' | translate}}</span>
    </div>
    <div class="edit-project-dialog-content edit-project-dialog-loader loader-inline">
      <img src="../../../../assets/spinner.gif" class="rotate-spinner">
    </div>
  </div>
</div>
